import React, { useState, useCallback, memo } from "react"
import { Text, Col, PageView, Table, Button } from 'components/YeyComponents'
import { colors } from 'utils/StyleUtils'
import api from 'services/api'
import { isActiveLOV, paginationLimit, queryParamsConstructor } from 'utils/Utils'
import { BsThreeDotsVertical } from "react-icons/bs"
import { useHistory } from 'react-router-dom'
import { Menu, MenuItem } from "@material-ui/core"

const Usuario = () => {
    const defaultFilterValues = [
        { name: 'name', operator: 'contains', type: 'string' },
        { name: 'username', operator: 'contains', type: 'string' },
        { name: 'email', operator: 'contains', type: 'string' },
        { name: 'isActive', operator: 'eq', type: 'string' },
    ]

    const [newFilterValue, setNewFilterValue] = useState(defaultFilterValues)
    const [limit, setLimit] = useState(paginationLimit)
    const history = useHistory()

    const loadData = (props = {}) => {
        const { skip = 0, sortInfo = "", groupBy = "", filterValue = newFilterValue } = props

        const queryParams = queryParamsConstructor({ limit, skip, sortInfo, groupBy, filterValue })

        return api.get("users", { params: queryParams }).then(response => {
            const totalCount = response?.data?.total
            return {
                data: response?.data?.users?.map(c => {
                    return c
                }),
                count: totalCount * 1
            }
        })
    }

    const dataSource = useCallback(loadData, [newFilterValue, limit])

    const ActionComponent = memo(({ data }) => {
        const [isMenuVisible, setIsMenuVisible] = useState(null)

        const handleClick = (event) => {
            setIsMenuVisible(event?.currentTarget)
        }

        const handleClose = () => {
            setIsMenuVisible(false)
        }

        const editUser = () => {
            setIsMenuVisible(false)
            history.push(`/menu/cadastro/usuario/${data?.id}`)
        }

        return (
            <>
                <div id="action" onClick={handleClick}>
                    <BsThreeDotsVertical size={20} />
                </div>
                <Menu
                    id="simple-menu"
                    anchorEl={isMenuVisible}
                    keepMounted
                    open={Boolean(isMenuVisible)}
                    onClose={handleClose}
                >
                    <MenuItem onClick={editUser}>Editar Usuário</MenuItem>
                </Menu>
            </>
        )
    })

    const userActiveRender = useCallback(({ value }) => {
        if (value) return 'Sim'
        else return 'Não'
    }, [])

    const userRoleRender = useCallback(({ value }) => {
        switch (value) {
            case 'admin':
                return 'Administrador'
            case 'support':
                return 'Suporte'
            default:
              return ''
        }
    }, [])

    return (
        <PageView>
            <Col align={{ horizontal: 'flex-start' }} flex={1} height='99%'>
                <Text
                    type='title'
                    color={colors.darkGrey}
                >
                    Usuários
                </Text>
                {/* eslint-disable-next-line react/style-prop-object */}
                <Button style='submit' viewStyle={{ padding: { bottom: '1em' }, align: 'flex-start' }} onClickFunction={() => history.push("/menu/cadastro/usuario/novo")}>Adicionar Usuário</Button>
                <Table
                    title='Usuários cadastrados'
                    data={dataSource}
                    limit={limit}
                    columns={[
                        { name: 'username', defaultFlex: 0.5, header: 'Usuário', },
                        { name: 'name', defaultFlex: 1, header: 'Nome', },
                        { name: 'email', defaultFlex: 1, header: 'E-mail', },
                        {
                          name: 'role',
                          defaultFlex: 1,
                          header: 'Cargo',
                          render: userRoleRender,
                        },
                        {
                            name: 'isActive',
                            defaultFlex: 0.25,
                            header: 'Ativo',
                            render: userActiveRender,
                            filterType: 'select',
                            filterEditorProps: {
                              placeholder: 'Todos',
                              dataSource: isActiveLOV,
                            }
                        },
                        {
                            name: 'action',
                            header: '',
                            width: 20,
                            render: ({ data }) => <ActionComponent data={data} />,
                            sortable: false,
                        },
                    ]}
                    defaultFilterValues={defaultFilterValues}
                    sortable
                    pagination={true}
                    onFilterValueChange={setNewFilterValue}
                    onLimitChange={setLimit}
                />
            </Col>
        </PageView>
    )
}

export default Usuario
