import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import ModalUi from '@material-ui/core/Modal'

function rand() {
    return Math.round(Math.random() * 20) - 10
}

function getModalStyle() {
    const top = -20//20 + rand()
    const left = 55 + rand()

    return {
        top: `${top}%`,
        left: `${left}%`,
        transform: `translate(-${top}%, -${left}%)`,
    }
}

const useStyles = makeStyles((theme) => ({
    paper: {
        position: 'absolute',
        width: '30em',
        backgroundColor: theme.palette.background.paper,
        //border: '2px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
    },
}))

const Modal = (props) => {
    const { isVisible, setIsVisible, children } = props

    const classes = useStyles()

    // getModalStyle is not a pure function, we roll the style only on the first render
    const [modalStyle] = useState(getModalStyle)
    const [open, setOpen] = useState(false)

    const handleOpen = () => {
        setOpen(true)
    }

    const handleClose = () => {
        setOpen(false)
        setIsVisible(false)
    }

    useEffect(() => {
        if (isVisible) setOpen(true)
        else setOpen(false)
    }, [isVisible])

    const body = (
        <div style={modalStyle} className={classes.paper}>
            {children}
            <Modal />
        </div>
    )
    //console.log('rand', rand())

    return (
        <div>
            <ModalUi
                open={open}
                onClose={handleClose}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
            >
                {body}
            </ModalUi>
        </div>
    )
}

export default Modal
