import { Row } from 'components/common/layout/LayoutStyle'
import { Col, FormInput, FormSubmit, PageView, Text } from 'components/YeyComponents'
import React from 'react'
import { useForm } from 'react-hook-form'
import { useDispatch } from 'react-redux'
import { useHistory, withRouter } from 'react-router-dom'
import { signIn } from 'redux/actions/auth'
import FuturoAbsolutoLogoBlack from 'assets/images/futuroAbsolutoBlack.png'
//import logoYey from 'assets/images/logoYey.jpg'

const Login = () => {
    const dispatch = useDispatch()
    const history = useHistory()

    const { handleSubmit, control, formState: { errors } } = useForm()
    const onSubmit = data => dispatch(signIn(data?.username, data?.password, history))

    return (
        <PageView backgroundColor='black'>
            <Row flexDirection="column" align={{ vertical: 'center', horizontal: 'center' }} width='100%' height='100%'>
                <Col padding={{ bottom: '1rem' }}>
                    {/* <img src={logoYey} alt="Logo Yey Company :)" width='250' /> */}
                    <img src={FuturoAbsolutoLogoBlack} alt="Logo Futuro Absoluto" width='250' />
                </Col>
                <Col>
                    <form className="form" onSubmit={handleSubmit(onSubmit)} style={{ border: '1px solid black', borderRadius: '1rem', padding: '2rem', backgroundColor: 'white' }}>
                        <Text type='title'>Login</Text>
                        <Row>
                            <Col width='100%'>
                                <FormInput
                                    name='username'
                                    label='Nome de usuário'
                                    required
                                    control={control}
                                    errors={errors}
                                    width='30em'
                                />
                                <FormInput
                                    type='password'
                                    name='password'
                                    label='Senha'
                                    required
                                    control={control}
                                    errors={errors}
                                    width='30em'
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col width='100%'>
                                <FormSubmit label="Entrar" padding={{ top: '2em' }} />
                            </Col>
                        </Row>
                    </form>
                </Col>
                <Col padding={{ top: '2rem' }}>
                    <p style={{ color: 'white' }}>Secon - Sistema de Emissão de Contratos de Estágio</p>
                </Col>
            </Row>
        </PageView>
    )
}

export default withRouter(Login)
