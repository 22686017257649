const errorHandler = (error, expired = false) => {
    const errorObj = {
      400: () => `O formato dos campos pode estar incorreto. Tente novamente ou contate a equipe de suporte.`,
      401: () => expired ? `Acesso expirado. ` : `Usuário e/ou senha incorretos. Se você esqueceu sua senha, entre em contato com o suporte para solicitar nova senha. `,
      403: () => `Você não possui permissão para acessar este recurso. `,
      404: () => `Recurso não encontrado. `,
      500: () => `Erro interno do servidor/API. Tente novamente ou contate a equipe de suporte. `,
      unhandledCode: (httpCode, details) => `Código HTTP: ${httpCode}. ${details ? 'Detalhes: ' + details : ''}`,
      default: () => `Ocorreu um erro desconhecido. Entre em contato com a equipe de suporte. `
    }
    if(error && error.response) {
      if(errorObj[error.response.status]) {
        return errorObj[error.response.status]()
      } else {
        return errorObj.unhandledCode(error.response.status, error.response?.data?.message)
      }
    } else {
      return errorObj.default()
    }
  }

  export default errorHandler
