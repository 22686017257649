const colors = {
    // Cores utilizadas no tema do produto
    backgroundColor: '#FFFFFF', //#E3E3E3
    fontColor: '#000000',
    mainColor: '',

    purple1: '#3CF361',
    purple2: '#52307c',
    purple3: '#351D4D',

    // Cores utilizadas para manter a padronizacao da palheta de cores do produto como um todo
    white: '#FFFFFF',
    orange: '#FF0000',
    red:  '#D00000', //'#B10202',
    black: '#000000',
    grey: '#888888',
    lightGrey: '#BBBBBB', //#c9cbce
    darkGrey: '#1D1D1D',//'#333333'
}

export default colors
