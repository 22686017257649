import React from "react"
import DefaultRoute from "routes/defaultRoute"

import Escola from "pages/private/cadastro/escola"
import { Switch } from "react-router-dom"
import EscolaForm from "pages/private/cadastro/escola/form"

const EscolaRoutes = ({match}) => {
  const PARENT_PATH = match.url || ""
  return (
    <Switch>
      <DefaultRoute exact path={PARENT_PATH} component={Escola} />
      <DefaultRoute exact path={PARENT_PATH + "/novo"} component={EscolaForm} />
      <DefaultRoute exact path={PARENT_PATH + "/:schoolId"} component={EscolaForm} />
      <DefaultRoute />
    </Switch>
  )
}

export default EscolaRoutes
