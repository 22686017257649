import WORKING_SCHEDULE_TYPES from "redux/types/workingSchedule"

const INITIAL_STATE = {
  weekDays: [
    {weekdayId: 1, hours: 0},
    {weekdayId: 2, hours: 0},
    {weekdayId: 3, hours: 0},
    {weekdayId: 4, hours: 0},
    {weekdayId: 5, hours: 0},
    {weekdayId: 6, hours: 0},
    {weekdayId: 7, hours: 0},
  ],
  minWorkload: 20,
  maxWorkload: 30
}

export default function workingScheduleReducer(state = INITIAL_STATE, action = {}) {
  switch (action.type) {
    case WORKING_SCHEDULE_TYPES.ADD_WORKLOAD:
      return {...state, weekDays: [...state.weekDays.map(w => {
        if(w.weekdayId === Number(action.weekDayId)) {
          w.hours = action.workLoadValue
        }
        return w
      })], }
    default:
      return state
  }
}