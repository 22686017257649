import React from "react"
import DefaultRoute from "routes/defaultRoute"

import Usuario from "pages/private/cadastro/usuario"
import { Switch } from "react-router-dom"
import UsuarioForm from "pages/private/cadastro/usuario/form"

const UsuarioRoutes = ({match}) => {
  const PARENT_PATH = match.url || ""
  return (
    <Switch>
      <DefaultRoute exact path={PARENT_PATH} component={Usuario} />
      <DefaultRoute exact path={PARENT_PATH + "/novo"} component={UsuarioForm} />
      <DefaultRoute exact path={PARENT_PATH + "/:userId"} component={UsuarioForm} />
      <DefaultRoute />
    </Switch>
  )
}

export default UsuarioRoutes
