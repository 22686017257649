import AUTH_TYPES from 'redux/types/auth'

const INITIAL_STATE = {
  id: null,
  username: null,
  name: null,
  roleId: null
}

export default function authReducer(state = INITIAL_STATE, action = {}) {
  switch (action.type) {
    case AUTH_TYPES.LOGIN:
      return {...state, ...action.data}
    case AUTH_TYPES.LOGOUT:
      return {...state, ...INITIAL_STATE}
    case AUTH_TYPES.UPDATE_USERDATA:
      return {...state, ...action.userData}
    default:
      return state
  }
}
