import React from 'react'
import { Row } from "./LayoutStyle.js"

const RowView = (props) => {
    const { children } = props

    return(
        <Row {...props}>
            {children}
        </Row>
    )
}

export default RowView
