import React, { useState, useEffect, useCallback } from 'react'
import { LabelStyle } from './DatePickerStyle.js'
import { Row, Col, Text, Input } from 'components/YeyComponents'
import DatePickerComponent, { registerLocale } from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import pt from 'date-fns/locale/pt'
import format from 'date-fns/format'
import './DatePickerCss.css'
import InputMask from 'react-input-mask'
import { Portal } from 'react-overlays'

registerLocale('pt', pt) // Criar funcao

const DatePicker = (props) => {
    const { label, labelProps, value, onChangeValue, placeholder, onSelect, readOnlyHandler, locale, dateFormat, disabled, showTimeSelect, showTimeSelectOnly, popperPlacement } = props
    const [years, setYears] = useState([])
    const MAX_YEARS = 2

    const changeValueHandler = useCallback((newValue) => {
        onChangeValue && typeof onChangeValue == 'function' && onChangeValue(newValue)
    }, [])

    const selectHandler = useCallback((newValue) => {
        onSelect && typeof onSelect == 'function' && onSelect(newValue)
    }, [])

    useEffect(() => {
        let yearsArray = []
        for (var i = 1930; i <= new Date().getFullYear() + MAX_YEARS; i++) {
            //console.log('fori', i)
            yearsArray.push(i)
        }
        setYears(yearsArray)
    }, [])

    const CalendarContainer = ({children}) => {
        const el = document.getElementById('calendar-portal')

        return (
            <Portal container={el}>
                {children}
            </Portal>
        )
    }

    const months = [
        "Janeiro",
        "Fevereiro",
        "Março",
        "Abril",
        "Maio",
        "Junho",
        "Julho",
        "Agosto",
        "Setembro",
        "Outubro",
        "Novembro",
        "Dezembro",
    ]

    return (
        <Col {...props}>
            {typeof label == 'string' &&
                <Text
                    margin={{ right: '1em' }}
                    type='label'
                    {...labelProps}
                >
                    {label}
                </Text>
            }
            <Row>
                <DatePickerComponent
                    // popperModifiers={{
                    //     offset: {
                    //         enabled: true,
                    //         offset: '5px, 10px'
                    //     },
                    //     preventOverflow: {
                    //         enabled: true,
                    //         escapeWithReference: false, // force popper to stay in viewport (even when input is scrolled out of view)
                    //         boundariesElement: 'viewport'
                    //     }
                    // }}
                    // className='ui-datepicker'
                    // popperProps={{
                    //     positionFixed: true, // use this to make the popper position: fixed

                    //     style: { position: 'absolute', backgroundColor: 'red', }
                    // }}
                    popperPlacement={popperPlacement}
                    timeIntervals={5}
                    timeCaption="Hora"
                    showTimeSelect={showTimeSelect || false}
                    showTimeSelectOnly={showTimeSelectOnly || false}
                    selected={value}
                    onChange={changeValueHandler}
                    onSelect={selectHandler}
                    locale={locale || 'pt'}
                    dateFormat={dateFormat || 'dd/MM/yyyy'}
                    customInput={
                        // <input value={value} onChange={onChangeValue} type="number" />
                        <Input value={value} placeholder={placeholder} onChangeValue={onChangeValue} readOnlyHandler={readOnlyHandler} />
                    }
                    popperContainer={CalendarContainer}
                    disabled={disabled}
                    fixedHeight
                    renderCustomHeader={({
                        date,
                        changeYear,
                        changeMonth,
                        decreaseMonth,
                        increaseMonth,
                        prevMonthButtonDisabled,
                        nextMonthButtonDisabled,
                    }) => (
                        <div
                            style={{
                                margin: 10,
                                display: "flex",
                                justifyContent: "center",
                            }}
                        >
                            <select
                                value={months[date.getMonth()]}
                                onChange={({ target: { value } }) =>
                                    changeMonth(months.indexOf(value))
                                }
                            >
                                {months.map((option) => (
                                    <option key={option} value={option}>
                                        {option}
                                    </option>
                                ))}
                            </select>
                            <select
                                value={date.getFullYear()}
                                onChange={({ target: { value } }) => changeYear(value)}
                            >
                                {years.map((option) => (
                                    <option key={option} value={option}>
                                        {option}
                                    </option>
                                ))}
                            </select>
                        </div>
                    )}
                />
            </Row>
        </Col>
    )
}

export default DatePicker
