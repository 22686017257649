import styled from "styled-components"
import { colors } from 'utils/StyleUtils'

const InputStyle = styled.input`
    color: ${props => props.color ? props.color : colors.fontColor};
    font-size: ${props => props.size ? props.size : '1.2em'};
    font-weight: ${props => props.fontWeight && props.fontWeight};
    width: 100%;

    border-style: ${props => props.borderStyle ? props.borderStyle : 'solid'};
    border-width: ${props => props.borderWidth ? props.borderWidth : '0px'};
    border-bottom-width: ${props => props.borderWidth ? props.borderWidth : '1px'};
    border-color: ${props => props.borderColor ? props.borderColor : colors.darkGrey};
    border-style: ${props => props.borderStyle ? props.borderStyle : 'solid'};
    ${'' /* border-radius: 3px; */}
`

const LabelStyle = styled.label`
    color: ${props => props.color ? props.color : colors.fontColor};
    font-size: ${props => props.size ? props.size : '1.2em'};
    font-weight: ${props => props.fontWeight && props.fontWeight};
`

const InputMaskStyle = {
    // borderColor: colors.black,
    // borderWidth: '0px',
    // borderBottomWidth: '2px',
    width: '20em',
    color: colors.fontColor,
}

export { InputStyle, LabelStyle, InputMaskStyle }