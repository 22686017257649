const BRLCurrencyConverter = value => { //Funciona até 1 quatrilhão de reais não inclusivo.
  let newValue = null, str = value.replace(/\./g, "").replace(/,/g,"").replace(/\D/g, "")
  if(str.length > 14) {
    return "0,00"
  } else {
    if(str.length > 5) {
      const strSplit = str.substring(0, str.length - 3)
      const division = str.substring(str.length - 3, str.length)
      newValue = `${strSplit}${division / 100}`
    } else newValue = (Number(str) / 100).toString()
    return Number(newValue).toLocaleString("pt-BR", {minimumFractionDigits: 2})
  }
}

export default BRLCurrencyConverter
