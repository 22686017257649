import styled from "styled-components"
import { colors, setSpacing, setAlignment } from 'utils/StyleUtils'

const SubmitStyle = styled.input.attrs(props => ({
    type: 'submit',
    value: props.label ? props.label : 'Enviar'
}))`
    display:  ${props => props.display || 'flex'};
    flex: ${props => props.flex && props.flex};
    float: ${props => props.float && props.float};

    width: ${props => props.width ? props.width : 'auto'};
    height: ${props => props.height ? props.height : 'auto'};

    padding-top: ${props => setSpacing(props.padding, 'top')};
    padding-bottom: ${props => setSpacing(props.padding, 'bottom')};
    padding-left: ${props => setSpacing(props.padding, 'left')};
    padding-right: ${props => setSpacing(props.padding, 'right')};

    justify-content: ${props => setAlignment(props.align, 'vertical', 'center')};
    align-items: ${props => setAlignment(props.align, 'horizontal', 'center')};

    background-color: ${props => props.backgroundColor ? props.backgroundColor : colors.darkGrey};
    color: ${props => props.fontColor ? props.fontColor : colors.white};

    margin-top: ${props => setSpacing(props.margin, 'top')};
    margin-bottom: ${props => setSpacing(props.margin, 'bottom')};
    margin-left: ${props => setSpacing(props.margin, 'left')};
    margin-right: ${props => setSpacing(props.margin, 'right')};

    border:${props => props.border ? props.border : 'none'};

    font-size: ${props => props.fontSize ? props.fontSize : '1.2em'};

    &:focus {
        outline: none;
        box-shadow: 1px 1px 1px 1px ${colors.darkGrey};
    }
    &:active {
        color: ${colors.darkGrey};
        background-color: ${colors.white};
    }
  `

export { SubmitStyle }
