import SCHOOL_TYPES from 'redux/types/school'

const INITIAL_STATE = {
  id: null,
  name: null,
  zipcode: null,
  street: null,
  number: null,
  complement: null,
  district: null,
  stateId: null,
  cityId: null,
  isActive: null,
  createdAt: null,
  updatedAt: null
}

export default function schoolReducer(state = INITIAL_STATE, action = {}) {
  switch (action.type) {
    case SCHOOL_TYPES.GET_SCHOOL:
      return {...state, ...action.school}
    case SCHOOL_TYPES.CLEAR:
      return {...state, ...INITIAL_STATE}
    default:
      return state
  }
}
