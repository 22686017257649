/** Texto default do sistema
 * @param {text} children - Texto mostrado na tela.
 *
 * Styles
 * @param {number, string} width - Largura do texto, valor em porcentagem. Ex: 0.7 ou '60%'.
 * @param {number, string} height - Altura do texto, valor em porcentagem. Ex: 0.2 ou '30%'.
 * @param {string, object} align - Alinhamento do texto, opções: 'flex-start', 'center', 'flex-end'. Se for uma string aplicará nos dois eixos. Se for um objeto pode receber {horizontal: <string>, vertical: <string>} para aplicar dois alinhamentos distintos (default: 'flex-start').
 * @param {string} backgroundColor - Hex da cor de fundo do texto. Ex: '#FF0066'.
 * @param {object} border - Recebe as propriedades: {width: <number>, color: <string>}.
 * @param {string, number, object} margin - Espaçamento da view do texto, exemplos: '10%', 0.3. Se for uma string aplicará nos 4 cantos. Se for um objeto pode receber {top: <value>, bottom: <value>, left: <value>, right: <value>} para aplicar espaçamentos diferentes.
 * @param {string, number, object} padding - Espaçamento do texto até sua view, exemplos: '20%', 0.45. Se for uma string aplicará nos 4 cantos. Se for um objeto pode receber {top: <value>, bottom: <value>, left: <value>, right: <value>} para aplicar espaçamentos diferentes.
 * @param {number} size - Tamanho da fonte do texto, se não for passado nada, o padrão retornado será 14.
 * @param {boolean} bold - Aplica a fonte 'Neris-SemiBold'.
 * @param {string} fontFamily - Aplica uma nova fonte.
 * @param {string} color - Hex da cor do texto (default: '#000').
**/

import React, { memo, useMemo, useCallback, useState, useEffect } from 'react'
import { Text, Col, Row, DatePicker as YeyDatePicker, Input as YeyInput, Dropdown as YeyDropdown } from 'components/YeyComponents'
import { useForm, Controller } from 'react-hook-form'
import { colors } from 'utils/StyleUtils'
import { SubmitStyle } from './FormStyle'
import { Checkbox, CheckboxGroup } from "@chakra-ui/react"
import "./Styles.css"
import { parseISO } from 'date-fns'

// Error Component
const errorMessage = ({ type }) => {
    let textoErro = ''
    switch (type) {
        case 'maxLength':
            textoErro = 'Tamanho excedido'
            break
        case 'required':
            textoErro = 'Campo necessário'
            break
        case 'moreThanValid':
            textoErro = 'Horas máximas por semana excedida (30)'
            break
        case 'lessThanValid':
            textoErro = 'Horas mínimas não alcançadas por semana (20)'
            break
        case 'exist':
            textoErro = 'Horas mínimas não alcançadas por semana (20)'
            break
        case 'passwordMatch':
            textoErro = 'Senhas devem ser iguais'
            break
        default:
            textoErro = 'Valores inseridos estão incorretos'
    }

    return <Text size='1em' color={colors.red}>{textoErro}</Text>
}

// Componentes
const Input = memo((props) => {
    const { name, label, control, mask, maskPlaceholder, defaultValue, placeholder, labelProps, required, errors, type, width, getChangeValue, validate, disabled, transform, changeValue } = props

    const InputComponent = memo(({ onChange, onBlur, value, ref, label, required, getChangeValue, type, mask, width, name }) => {
        const [checked, setChecked] = useState(false)

        useEffect(() => {
            if (value && value !== '' && value > 0) {
                setChecked(true)
            }
            else {
                setChecked(false)
            }
        }, [value])

        const onChangeHandler = useCallback((newValue) => {
            let newValueHandler = newValue
            if (changeValue && typeof changeValue === 'function') {
                newValueHandler = changeValue(newValue)
            }
            getChangeValue && typeof getChangeValue === 'function' && getChangeValue(newValueHandler?.target?.value, name)
            onChange(newValueHandler)
        }, [getChangeValue, changeValue])

        if (type === 'weekDay') {
            return <Col width={width || 'auto'} flex={1} maxWidth='30em' padding={{ top: '1em' }} {...props}>
                {label && <Text required={required} {...labelProps} padding={{ bottom: '0.1em' }} >{label}</Text>}
                <Row align={{ vertical: 'center' }}>
                    <YeyInput
                        value={value}
                        onChangeValue={onChangeHandler}
                        onChange={onChangeHandler}
                        mask={mask}
                        maskPlaceholder={maskPlaceholder}
                        type='number'
                        //width='100%'
                        keyValue={name}
                        disabled={disabled}
                    />
                    <Checkbox pl='1em' size='md' colorScheme="blue" isChecked={checked} isReadOnly />
                </Row>
                {errors && errors[name] && errorMessage({ type: errors[name].type })}
            </Col>
        }
        else {
            return <Col width={width || 'auto'} flex={1} maxWidth='30em' padding={{ top: '1em' }} {...props}>
                {label && <Text required={required} {...labelProps} padding={{ bottom: '0.1em' }}>{label}</Text>}
                {/* <input
                    onChange={(e) => onChange(transform.output(e))}
                    value={transform.input(value)}
                /> */}
                <YeyInput
                    value={value}
                    onChange={onChangeHandler}
                    mask={mask}
                    // maskPlaceholder={maskPlaceholder}
                    // placeholder={placeholder}
                    type={type}
                    keyValue={name}
                    width='100%'
                    disabled={disabled}
                />
                {errors && errors[name] && errorMessage({ type: errors[name].type })}
            </Col>
        }
    })

    return (
        <Controller
            control={control}
            name={name}
            defaultValue={defaultValue}
            rules={{ required: required, validate: validate }}
            transform={transform}
            render={({ field: { onChange, onBlur, value, ref } }) => {
                return <InputComponent mask={mask} name={name} width={width} maskPlaceholder={maskPlaceholder} onChange={onChange} onBlur={onBlur} value={value} ref={ref} label={label} required={required} getChangeValue={getChangeValue} type={type} {...props} />
            }}
        />
    )
})

const DataArray = (props) => {
    const { name, label, mask, maskPlaceholder, value, onChange, labelProps, register, required, errors, validate, } = props
    const [checked, setChecked] = useState(false)

    useEffect(() => {
        if (value && value !== '' && value > 0) {
            setChecked(true)
        }
        else {
            setChecked(false)
        }
    }, [value])

    return (
        <Col padding={{ top: '1em' }} {...props}>
            {label && <Text required={required} {...labelProps} padding={{ bottom: '0.1em' }}>{label}</Text>}
            <Row align={{ vertical: 'center' }}>
                <YeyInput
                    value={value}
                    onChangeValue={onChange}
                    mask={mask}
                    maskPlaceholder={maskPlaceholder}
                    type='number'
                    width='10em'
                />
                <Checkbox pl='1em' size='md' colorScheme="blue" isChecked={checked} isReadOnly />
            </Row>
            {errors && errors[name] && errorMessage({ type: errors[name].type })}
        </Col>
    )
}


const DatePicker = (props) => {
    const { name, control, defaultValue, label, labelProps, placeholder, required, errors, validate, width, disabled, dateFormat, showTimeSelect, showTimeSelectOnly } = props

    const DatePickerComponent = ({ onChange, onBlur, value, ref }) => {
        return <Col width={width || 'auto'} flex={1} maxWidth='30em' padding={{ top: '1em' }} {...props}>
            {label && <Text required={required} {...labelProps} padding={{ bottom: '0.1em' }}>{label}</Text>}
            <YeyDatePicker
                placeholder={placeholder}
                onChangeValue={onChange}
                value={value}
                readOnlyHandler={true}
                width='100%'
                disabled={disabled}
                dateFormat={dateFormat}
                showTimeSelect={showTimeSelect || false}
                showTimeSelectOnly={showTimeSelectOnly || false}
            />
            {errors && errors[name] && errorMessage({ type: errors[name].type })}
        </Col>
    }

    return (
        <Controller
            control={control}
            defaultValue={defaultValue && parseISO(defaultValue)}
            name={name}
            rules={{ required: required, validate: validate }}
            render={({ field: { onChange, onBlur, value, ref } }) => {
                return <DatePickerComponent onChange={onChange} onBlur={onBlur} value={value} ref={ref} />
            }}
        />
    )
}

const Dropdown = memo((props) => {
    const { name, control, label, labelProps, multiSelect, values, defaultValue, closeMenuOnSelect, placeholder, required, errors, validate, getChangeValue, disabled } = props

    const DropdownComponent = ({ onChange, onBlur, value, ref }) => {

        const onChangeHandler = useCallback((newValue) => {
            onChange(newValue?.value)
            getChangeValue && typeof getChangeValue === 'function' && getChangeValue(newValue, name)
        }, [onChange])

        return <Col padding={{ top: '1em' }} {...props}>
            {label && <Text required={required} {...labelProps} padding={{ bottom: '0.1em' }}>{label}</Text>}
            <YeyDropdown
                inputRef={ref}
                values={values}
                selectedValues={Array.isArray(values) && values.find(c => c.value === value)}
                onChangeValue={onChangeHandler}
                multiSelect={multiSelect}
                closeMenuOnSelect={closeMenuOnSelect || true}
                placeholder={placeholder}
                disabled={disabled}
            />
            {errors && errors[name] && errorMessage({ type: errors[name].type })}
        </Col>
    }
    return (
        <Controller
            control={control}
            // defaultValue={defaultValue}
            name={name}
            rules={{ required: required, validate: validate }}
            render={({ field: { onChange, onBlur, value, ref } }) => {
                return <DropdownComponent onChange={onChange} onBlur={onBlur} value={value} ref={ref} />
            }}
        />
    )
})

const Submit = (props) => {
    const { disabled, label } = props
    return (
        <Row {...props}>
            <SubmitStyle
                disabled={disabled}
                label={label}
                type='submit'
                margin={{ top: '0.5em' }}
                padding={{ top: '0.5em', bottom: '0.5em', left: '1em', right: '1em' }}
                backgroundColor={disabled ? colors.lightGrey : colors.darkGrey}
            />
        </Row>
    )
}

const Form = memo((props) => {
    const { children, onFormSubmit } = props

    const { register, handleSubmit, control, watch, formState: { errors } } = useForm()

    let subComponents = React.Children.map(children, (child, index) => {
        const { values, multiSelect, name, label, labelProps, placeholder, required, mask, maskPlaceholder, defaultValue, type } = child?.props

        switch (child?.type?.name) {
            case 'Input':
                return <Input
                    formControl={control}
                    register={register}
                    placeholder={placeholder}
                    name={name}
                    label={label}
                    errors={errors}
                    required={required}
                    mask={mask}
                    maskPlaceholder={maskPlaceholder}
                    type={type}
                    transform={{
                        input: (value) => {
                            return isNaN(value) || value === 0 ? "" : value.toString()
                        },
                        output: (e) => {
                            const output = parseInt(e.target.value, 10);
                            return isNaN(output) ? 0 : output;
                        }
                    }}
                />
                break

            case 'DatePicker':
                return <DatePicker
                    control={control}
                    name={name}
                    rules={{ required: required }}
                    render={({ field: { onChange, onBlur, value, ref } }) => {
                        // console.log('datepicker render2:', value, onChange)
                        return <DatePicker
                            onChange={onChange}
                            value={value}
                            register={register}
                            placeholder={placeholder}
                            name={name}
                            label={label}
                            errors={errors}
                            ref={ref}
                            required={required}
                        />
                    }}
                />
                break

            case 'Dropdown':
                return <Controller
                    control={control}
                    name={name}
                    rules={{ required: required }}
                    render={({ field: { onChange, onBlur, value, ref } }) => (
                        <Dropdown
                            ref={ref}
                            onChange={onChange}
                            values={values}
                            value={value}
                            register={register}
                            placeholder={placeholder}
                            name={name}
                            label={label}
                            errors={errors}
                            multiSelect={multiSelect}
                            required={required}
                        />
                    )}
                />
                break

            case 'DataArray':
                return <Col padding={{ top: '1em' }} {...child?.props}>
                    {label && <Text required={required} {...labelProps} padding={{ bottom: '0.1em' }}>{label}</Text>}

                    {values && Array.isArray(values) && values.length > 0 && values.map(({ name, label }) => {
                        return <Controller
                            control={control}
                            name={name}
                            rules={{ required: required }}
                            render={({ field: { onChange, onBlur, value, ref } }) => {
                                return <DataArray
                                    onChange={onChange}
                                    value={value}
                                    register={register}
                                    placeholder={placeholder}
                                    name={name}
                                    label={label}
                                    errors={errors}
                                    ref={ref}
                                    required={required}
                                />
                            }}
                        />
                    })}
                </Col>
                break

            case 'Submit':
                return <Submit />
                break

            default:
                return <></>
                break
        }
        // if (child?.type?.name === key) {
        //     return child
        // }
    })

    const onSubmitHandler = useCallback((sentData) => {
        //console.log('onSubmitHandler', onFormSubmit)
        if (onFormSubmit && typeof onFormSubmit === 'function') {
            onFormSubmit(sentData)
        }
        else {
            console.log('onSubmit function not associated', sentData)
        }
    }, [onFormSubmit])

    return (
        <Col {...props}>
            <form onSubmit={handleSubmit(onSubmitHandler)}>
                {subComponents.map((component) => component)}
            </form>
        </Col>
    )
})

Form.Input = Input
Form.DatePicker = DatePicker
Form.Dropdown = Dropdown
Form.DataArray = DataArray
Form.Submit = Submit

export {
    errorMessage,
    Input,
    DataArray,
    DatePicker,
    Dropdown,
    Submit,
    Form
}
