export const scholarshipLOV = [
    { id: 'fst_grade', label: '1º Ano Ensino Médio', value: 'fst_grade', },
    { id: 'scn_grade', label: '2º Ano Ensino Médio', value: 'scn_grade', },
    { id: 'trd_grade', label: '3º Ano Ensino Médio', value: 'trd_grade', },
    { id: 'graduate', label: 'Ensino Superior', value: 'graduate', },
]

export const workingScheduleLOV = [
    { label: "Escala 5x2", value: "5b2" },
    { label: "Escala 6x1", value: "6b1" },
]

export const statusLOV = [
    { id: 'awaiting_signature', label: 'Aguardando Assinatura', value: 'awaiting_signature', color: 'yellow', acceptedValues: ['awaiting_signature', 'active', 'revoked', 'expired', 'cancelled'] },
    { id: 'signed', label: 'Assinado', value: 'signed', color: 'blue', acceptedValues: ['awaiting_signature', 'active', 'revoked', 'expired', 'cancelled'] },
    { id: 'active', label: 'Ativo', value: 'active', color: 'green', acceptedValues: ['awaiting_signature', 'active', 'revoked', 'expired', 'cancelled'] },
    { id: 'expired', label: 'Encerrado', value: 'expired', color: 'grey', acceptedValues: ['awaiting_signature', 'active', 'revoked', 'expired', 'cancelled'] },
    { id: 'revoked', label: 'Rescindido', value: 'revoked', color: 'red', acceptedValues: ['awaiting_signature', 'active', 'revoked', 'expired', 'cancelled'] },
    { id: 'cancelled', label: 'Cancelado', value: 'cancelled', color: 'red', acceptedValues: ['awaiting_signature', 'active', 'revoked', 'expired', 'cancelled'] },
]

export const isActiveLOV = [
    { id: 'Não', label: 'Não', value: 'Não' },
    { id: 'Sim', label: 'Sim', value: 'Sim' },
    { id: 'Todos', label: 'Todos', value: 'Todos' }
]

export const paginationLimit = 15

export const queryParamsConstructor = ({ limit, skip = 0, sortInfo = "", groupBy = "", filterValue }) => {
    const queryParams = {
        page: (limit + skip) / limit,
        pageSize: limit
    }

    filterValue.forEach(filterElement => {
        if (typeof filterElement?.name !== 'undefined' && filterElement?.value) {
            if (filterElement?.name === 'startDate' || filterElement?.name === 'endDate') {
                queryParams[filterElement.name] = new Date(filterElement?.value).toLocaleDateString('fr-CA')
                // queryParams[filterElement.name] = format(new Date(filterElement.value), 'yyyy-MM-dd')
            } else if (filterElement?.name === 'isActive') {
                let value = filterElement?.value
                switch (value) {
                    case 'Sim':
                        value = true
                        break
                    case 'Não':
                        value = false
                        break
                    default:
                        value = null
                        break
                }
                if (value !== null) {
                    queryParams[filterElement.name] = value
                }
            }
            else {
                queryParams[filterElement.name] = filterElement.value
            }
        }
    })

    return queryParams

}
