import React, { useState, useCallback, memo } from "react"
import { Text, Col, PageView, Table, Button } from 'components/YeyComponents'
import { colors } from 'utils/StyleUtils'
import api from 'services/api'
import { isActiveLOV, paginationLimit, queryParamsConstructor } from 'utils/Utils'
import { BsThreeDotsVertical } from "react-icons/bs"
import { useHistory } from 'react-router-dom'
import { Menu, MenuItem } from "@material-ui/core"
import { downloadCustomerRelations, } from 'redux/actions/report'
import { useDispatch, } from "react-redux"

const Empresa = () => {
    const defaultFilterValues = [
        { name: 'companyName', operator: 'contains', type: 'string' },
        { name: 'cnpj', operator: 'contains', type: 'string' },
        { name: 'isActive', operator: 'eq', type: 'string' }
        // { name: 'city', operator: 'contains', type: 'string' },
        // { name: 'stateAbbr', operator: 'contains', type: 'string' },
    ]

    const [newFilterValue, setNewFilterValue] = useState(defaultFilterValues)
    const [limit, setLimit] = useState(paginationLimit)
    const history = useHistory()

    const dispatch = useDispatch()

    const loadData = (props = {}) => {
        const { skip = 0, sortInfo = "", groupBy = "", filterValue = newFilterValue } = props

        const queryParams = queryParamsConstructor({ limit, skip, sortInfo, groupBy, filterValue })

        return api.get("companies", { params: queryParams }).then(response => {
            const totalCount = response?.data?.total
            return {
                data: response?.data?.companies?.map(c => {
                    return c
                }),
                count: totalCount * 1
            }
        })
    }

    const dataSource = useCallback(loadData, [newFilterValue, limit])

    const companyActiveRender = useCallback(({ value }) => {
        if (value) return 'Sim'
        else return 'Não'
    }, [])

    const ActionComponent = memo(({ data }) => {
        const [isMenuVisible, setIsMenuVisible] = useState(null)

        const handleClick = (event) => {
            setIsMenuVisible(event?.currentTarget)
        }

        const handleClose = () => {
            setIsMenuVisible(false)
        }

        const editCompany = () => {
            setIsMenuVisible(false)
            history.push(`/menu/cadastro/empresa/${data?.id}`)
        }

        const getCustomersRelations = () => {
            dispatch(downloadCustomerRelations(data?.id))
        }

        return (
            <>
                <div id="action" onClick={handleClick}>
                    <BsThreeDotsVertical size={20} />
                </div>
                <Menu
                    id="simple-menu"
                    anchorEl={isMenuVisible}
                    keepMounted
                    open={Boolean(isMenuVisible)}
                    onClose={handleClose}
                >
                    <MenuItem onClick={editCompany}>Editar Empresa</MenuItem>
                    <MenuItem onClick={getCustomersRelations}>Gerar Lista de Estagiários</MenuItem>
                </Menu>
            </>
        )
    })

    return (
        <PageView>
            <Col align={{ horizontal: 'flex-start' }} flex={1} height='99%'>
                <Text
                    type='title'
                    color={colors.darkGrey}
                >
                    Empresas
                </Text>
                {/* eslint-disable-next-line react/style-prop-object */}
                <Button style='submit' viewStyle={{ padding: { bottom: '1em' }, align: 'flex-start' }} onClickFunction={() => history.push("/menu/cadastro/empresa/novo")}>Adicionar empresa</Button>
                <Table
                    title='Empresas cadastradas'
                    data={dataSource}
                    limit={limit}
                    columns={[
                        { name: 'id', header: '#', type: 'number', defaultWidth: 5, defaultVisible: false, defaultFlex: 0.5},
                        { name: 'cnpj', header: 'CNPJ', defaultWidth: 30, defaultFlex: 1.5},
                        { name: 'companyName', header: 'Nome', defaultWidth: 50, defaultFlex: 3},
                        { name: 'tradeName', header: 'Nome Fantasia/Filial', defaultWidth: 50, defaultFlex: 4},
                        { name: 'city', header: 'Cidade', defaultWidth: 10, defaultFlex: 3},
                        { name: 'stateAbbr', defaultFlex: 0.75, header: 'UF', defaultWidth: 5},
                        {
                          name: 'isActive',
                          defaultFlex: 1,
                          header: 'Ativo',
                          filterType: 'select',
                          filterEditorProps: {
                            placeholder: 'Todos',
                            dataSource: isActiveLOV,
                          },
                          render: companyActiveRender,
                        },
                        {
                            name: 'action',
                            header: '',
                            width: 20,
                            defaultFlex: 0.25,
                            render: ({ data }) => <ActionComponent data={data} />,
                            sortable: false,
                        },
                    ]}
                    defaultFilterValues={defaultFilterValues}
                    sortable
                    pagination={true}
                    onFilterValueChange={setNewFilterValue}
                    onLimitChange={setLimit}
                />
            </Col>
        </PageView>
    )
}

export default Empresa
