import React from 'react'
import { Column } from "./LayoutStyle.js"

const ColumnView = (props) => {
    const { children } = props

    return(
        <Column {...props}>
            {children}
        </Column>
    )
}

export default ColumnView
