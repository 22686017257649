import toast from "react-hot-toast"
import errorHandler from "utils/errorHandler"

const { default: DASHBOARD_TYPES } = require("redux/types/dashboard")
const { default: api } = require("services/api")

const countContractsForDashboard = () => {
  return dispatch => {
    api.get("counts").then(response => {
      dispatch({
        type: DASHBOARD_TYPES.COUNT,
        data: response.data
      })
    }).catch(err => toast.error(errorHandler(err)))
  }
}

export {countContractsForDashboard}
