import React from "react"
import { Switch } from "react-router-dom"

import DefaultRoute from "routes/defaultRoute"
import EmpresaRoutes from "routes/private/cadastro/empresaRoutes"
import EscolaRoutes from "routes/private/cadastro/escolaRoutes"
import UsuarioRoutes from "routes/private/cadastro/usuarioRoutes"

import generateRoutePath from "utils/generateRoutePath"

const CadastroRoutesGroup= ({match}) => {
  const PARENT_PATH = match.url || ""
  const routes = generateRoutePath(["empresa", "escola", "usuario"])
  return (
    <Switch>
      <DefaultRoute path={PARENT_PATH + routes.empresa} component={EmpresaRoutes} />
      <DefaultRoute path={PARENT_PATH + routes.escola} component={EscolaRoutes} />
      <DefaultRoute path={PARENT_PATH + routes.usuario} component={UsuarioRoutes} />
      <DefaultRoute />
    </Switch>
  )
}

export default CadastroRoutesGroup