import React from "react"
import { Switch } from "react-router-dom"

import DefaultRoute from "routes/defaultRoute"
import ContratoRoutes from "routes/private/estagio/contratoRoutes"

import generateRoutePath from "utils/generateRoutePath"

const EstagioRoutesGroup = ({match}) => {
  const PARENT_PATH = match.url || ""
  const routes = generateRoutePath(["contratos", "rescisao"])
  return (
    <Switch>
      <DefaultRoute path={PARENT_PATH + routes.contratos} component={ContratoRoutes} />
      <DefaultRoute />
    </Switch>
  )
}

export default EstagioRoutesGroup
