import React, { useEffect, useState, useCallback } from 'react'
import { Text, PageView, FormInput, FormDatePicker, FormDropdown, FormSubmit, } from 'components/YeyComponents'
import toast from "react-hot-toast"
import { useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { loadAllCombos, loadCitiesFromState } from 'redux/actions/combos'
import BRLCurrencyConverter from 'utils/BRLCurrencyConverter'
import { Grid } from "@material-ui/core"
import { useParams } from 'react-router-dom'
import api from 'services/api'
import errorHandler from 'utils/errorHandler'
import { statusLOV, scholarshipLOV, workingScheduleLOV, } from 'utils/Utils'
import { clearAll, getCompanyById } from 'redux/actions/company'

const ContratoNovo = () => {
    const { handleSubmit, control, setValue, getValues, reset, formState: { errors } } = useForm()

    const params = useParams()

    const dispatch = useDispatch()
    const combos = useSelector(state => state.combos)
    const statesList = useSelector(state => state.combos.statesList)
    const citiesList = useSelector(state => state.combos.citiesList)
    const revocationReasonsList = useSelector(state => state.combos.revocationReasonsList)
    const companySelected = useSelector(state => state.company)

    const [editFlag, setEditFlag] = useState(false)
    const [isGraduate, setIsGraduate] = useState(false)
    const [readOnlyFlag, setReadOnlyFlag] = useState(false)
    const [enableRevocationDatePicker, setEnableRevocationDatePicker] = useState(false)

    useEffect(() => dispatch(loadAllCombos()), [dispatch])

    useEffect(() => {
      setValue("supervisorName", companySelected?.defaultSupervisor)
    }, [companySelected, setValue])

    useEffect(() => {
        async function fetchData() {
            let formDataArray = []

            let toastId = toast.loading(`Consultando contrato... `)
            await api.get(`contract/${params?.contractId}`)
                .then(async response => {
                    const { data } = response
                    const hourDifference = 3

                    // Valores de correcao dos dados retornados pelo banco (o horario salvo no DB esta incorreto, ja que deveria ser 03:00 do dia ao inves de 00:00 como esta atualmente, mas como eles estao de brincadeira eu tive que corrigir)
                    let startDateObj = typeof data?.startDate === 'string' && (new Date(data?.startDate)).getTime()
                    let endDateObj = typeof data?.endDate === 'string' && (new Date(data?.endDate)).getTime()
                    let birthDateObj = typeof data?.customer?.birthDate === 'string' && (new Date(data?.customer?.birthDate)).getTime()
                    let revocationDateObj = typeof data?.revokedAt === 'string' && (new Date(data?.revokedAt)).getTime()

                    const startDateHandler = startDateObj && new Date(startDateObj + (hourDifference * 60 * 60 * 1000))
                    const endDateHandler = endDateObj && new Date(endDateObj + (hourDifference * 60 * 60 * 1000))
                    const birthDateHandler = birthDateObj && new Date(birthDateObj + (hourDifference * 60 * 60 * 1000))
                    const revocationDateHandler = revocationDateObj && new Date(revocationDateObj + (hourDifference * 60 * 60 * 1000))

                    // Dados do contrato
                    formDataArray.push({ name: 'id', value: data?.id })
                    formDataArray.push({ name: 'policyNumber', value: data?.policyNumber })
                    formDataArray.push({ name: 'supervisorName', value: data?.supervisorName })
                    formDataArray.push({ name: 'wage', value: data?.wage })
                    formDataArray.push({ name: 'startDate', value: startDateHandler.toLocaleDateString({format: 'dd/mm/yyyy', includeHour: false})})
                    formDataArray.push({ name: 'endDate', value: endDateHandler.toLocaleDateString({format: 'dd/mm/yyyy', includeHour: false})})
                    formDataArray.push({ name: 'workStartTime', value: () => data?.workStartTime ? new Date('1970-01-01T' + data?.workStartTime + ":00") : null})

                    // Dados do estagiario
                    formDataArray.push({ name: 'name', value: data?.customer?.name })
                    formDataArray.push({ name: 'cpf', value: data?.customer?.cpfcnpj })
                    formDataArray.push({ name: 'rg', value: data?.customer?.rgie })
                    formDataArray.push({ name: 'birthDate', value: birthDateHandler.toLocaleDateString({format: 'dd/mm/yyyy', includeHour: false}) }) //parseISO(data?.customer?.birthDate)
                    formDataArray.push({ name: 'school', value: data?.schoolId })
                    formDataArray.push({ name: 'company', value: data?.companyId })
                    formDataArray.push({ name: 'insuranceCompany', value: data?.insuranceCompany })
                    formDataArray.push({ name: 'revocationReason', value: data?.revocationReasonId })
                    formDataArray.push({ name: 'revokedAt', value: revocationDateHandler && revocationDateHandler?.toLocaleDateString({format: 'dd/mm/yyyy', includeHour: false})})
                    formDataArray.push({ name: 'status', value: data?.status })
                    formDataArray.push({ name: 'workingSchedule', value: data?.workingSchedule })

                    // Dados de endereco
                    formDataArray.push({ name: 'district', value: data?.customer?.district })
                    formDataArray.push({ name: 'state', value: data?.customer?.stateId })
                    await dispatch(loadCitiesFromState(data?.customer?.stateId))
                    formDataArray.push({ name: 'city', value: data?.customer?.cityId })
                    formDataArray.push({ name: 'cep', value: data?.customer?.zipcode })
                    formDataArray.push({ name: 'complement', value: data?.customer?.complement })
                    formDataArray.push({ name: 'street', value: data?.customer?.street })
                    formDataArray.push({ name: 'number', value: data?.customer?.number })
                    formDataArray.push({ name: 'scholarship', value: data?.customer?.scholarship })
                    formDataArray.push({ name: 'graduationCourse', value: data?.customer?.graduationCourse })

                    if(data?.status === 'revoked') {
                      setEnableRevocationDatePicker(true)
                    } else {
                      setEnableRevocationDatePicker(false)
                    }

                    if (data?.customer?.scholarship === 'graduate') setIsGraduate(true)
                    setEditFlag(true)
                })
                .catch(err => {
                  console.log(err)
                  toast.error(`Erro ao encontrar contrato: ${errorHandler(err)}`)
                })
                .finally(() => toast.dismiss(toastId))

            formDataArray.forEach(item => {
              if(item?.name === 'insuranceCompany') {
                setValue(item?.name, combos?.insuranceData?.holder)
              } else if(item?.name === 'policyNumber') {
                setValue(item?.name, combos?.insuranceData?.policyCode)
              } else {
                setValue(item?.name, item?.value)
              }
            })
        }

        if (params?.contractId) {
          fetchData()
        } else {
            setEditFlag(false)
            setReadOnlyFlag(false)
            setValue("insuranceCompany", combos?.insuranceData?.holder)
            setValue("policyNumber", combos?.insuranceData?.policyCode)
        }

        return () => {
          if(params?.contractId) {
            reset()
            clearAll()
          }
        }

    }, [combos?.insuranceData, dispatch, params, reset, setValue])

    const onSubmit = (data) => {

        function checkForDate(rawDate, fieldName, shouldBeLessThanCurrent = false) {
          console.log(rawDate)
          let st = rawDate;
          let pattern = /(\d{2})\/(\d{2})\/(\d{4})/;
          let dateToCheck = new Date(st.replace(pattern,'$3-$2-$1') + 'T00:00:00')

          if (Object.prototype.toString.call(dateToCheck) === "[object Date]") {
            if (isNaN(dateToCheck)) {
              toast.error(`O campo ${fieldName} está com data inválida. `)
              return false
            } else {
              if(dateToCheck.getTime() > new Date().getTime() && shouldBeLessThanCurrent === true) {
                toast.error(`O campo ${fieldName} tem que conter uma data inferior à atual. `)
                return false
              }
              return dateToCheck
            }
          } else {
            toast.error(`Não foi possível converter para data. `)
            return false
          }
        }

        let dtBirth = checkForDate(data?.birthDate, 'data de nascimento', true)
        if(dtBirth instanceof Date === false) {
          return false
        }
        let startDate = checkForDate(data?.startDate, 'data de início do contrato', false)
        if(startDate instanceof Date === false) {
          return false
        }
        let endDate = checkForDate(data?.endDate, 'data de término do contrato', false)
        if(endDate instanceof Date === false) {
          return false
        }

        if(startDate.getTime() > endDate.getTime()) {
          toast.error(`A data de início deve ser menor que a data de término. `)
        }

        let revokedAt = ''
        if(data?.status === 'revoked') {
          revokedAt = checkForDate(data?.revokedAt, 'data de revogação do contrato', false)
          if(revokedAt instanceof Date === false) {
            return false
          }

          if(revokedAt.getTime() < startDate.getTime()) {
            toast.error(`A data de revogação deve ser maior que a data de início. `)
          }

          if(revokedAt.getTime() > endDate.getTime()) {
            toast.error(`A data de revogação deve ser menor que a data de término. `)
          }
        }

        const payload = {
            id: editFlag ? params?.contractId : null,
            customer: {
                name: data?.name,
                cpfcnpj: data?.cpf,
                rgie: data?.rg,
                zipcode: data?.cep,
                street: data?.street,
                number: data?.number,
                complement: data?.complement,
                district: data?.district,
                cityId: data?.city,
                stateId: data?.state,
                birthDate: dtBirth.toLocaleDateString('fr-CA'),
                scholarship: data?.scholarship,
            },
            workingSchedule: data?.workingSchedule,
            startDate: startDate.toLocaleDateString('fr-CA'),
            endDate: endDate.toLocaleDateString('fr-CA'),
            wage: Number(data?.wage.replace(/,/g, "").replace(/\./g, "")) / 100,
            supervisorName: data?.supervisorName,
            policyNumber: data?.policyNumber,
            insuranceCompany: data?.insuranceCompany,
            companyId: data?.company,
            schoolId: data?.school,
        }

        console.log('payload', editFlag, payload)

        if(data?.status === 'revoked') {
          payload.revokedAt = revokedAt.toLocaleDateString('fr-CA')
        }

        if (data?.scholarship === 'graduate') payload.customer.graduationCourse = data?.graduationCourse

        if (data?.workStartTime && typeof data?.workStartTime !== 'function'){
            payload.workStartTime = new Date(data?.workStartTime).toLocaleTimeString('pt-BR', {hour: '2-digit', minute:'2-digit'})
        }

        let toastId = toast.loading(`${params?.contractId ? 'Atualizando' : 'Cadastrando'} contrato...`)

        api.put('contract', payload)
            .then(response => {
                toast.success(`Contrato ${params?.contractId ? 'atualizado' : 'cadastrado'}  com sucesso.`, response)
                setValue("id", response?.data?.contractId)
                setValue("status", 'awaiting_signature')
                // history.goBack()
                setEditFlag(true)
                //setValue('id', )
                return response
            })
            .catch(err => toast.error(`Erro ao ${params?.contractId ? 'atualizar' : 'cadastrar'} contrato: ${errorHandler(err)}`))
            .finally(() => toast.dismiss(toastId))
    }

    const getGraduation = useCallback(({ value }) => {
        if (value === 'graduate') {
            setIsGraduate(true)
        }
        else {
            setValue("graduationCourse", null)
            setIsGraduate(false)
        }

    }, [setValue])

    const getCitiesByStateId = useCallback(state => {
        dispatch(loadCitiesFromState(state.value))
        setValue("state", state.value)
    }, [dispatch, setValue])

    const getCompanyDefaultSupervisor = useCallback(company => {
      dispatch(getCompanyById(company.value))
      setValue("company", company.value)
    }, [dispatch, setValue])

    const getCepChange = useCallback(state => {
        if (state && state.length === 9) {
            api.get(`common/cep/${state}`)
                .then(async response => {
                    const { data } = response

                    setValue('cep', data?.cep)
                    setValue('complement', data?.complement)
                    setValue('district', data?.district)
                    setValue('state', data?.state?.id)
                    await dispatch(loadCitiesFromState(data?.state?.id))
                    setValue('city', data?.city?.id)
                    setValue('street', data?.street)
                })
                .catch(err => toast.error(`Erro ao encontrar CEP: ${errorHandler(err)}`))
            // .finally(() => toast.dismiss(toastId))
        }
    }, [dispatch, setValue])

    return (
        <PageView>
            <Text type='title' padding={{ top: '1em' }}>Cadastro de contrato de estágio</Text>
            <form onSubmit={handleSubmit(onSubmit)} style={{ width: '100%', flex: 1 }}>
                {editFlag &&
                    <Grid container xl={6} lg={6} md={6} sm={12} xs={12} direction="column" >
                        <FormInput
                            name='id'
                            label='ID'
                            control={control}
                            errors={errors}
                            defaultValue=''
                            disabled
                        />
                    </Grid>
                }
                <Text fontWeight='bold' padding={{ top: '1em', }}>Dados do estagiário</Text>
                <Grid container direction="row" justify="flex-start" alignItems="flex-start" spacing={1}  >
                    <Grid item xl={6} lg={6} md={6} sm={12} xs={12} direction="column" >
                        <FormInput
                            name='name'
                            label='Nome'
                            placeholder={''}
                            required
                            control={control}
                            errors={errors}
                            defaultValue=''
                            disabled={readOnlyFlag}
                        />
                        <FormInput
                            name='cpf'
                            label='CPF'
                            mask='cpf'
                            required
                            control={control}
                            errors={errors}
                            disabled={readOnlyFlag}
                        />
                        <FormDropdown
                            placeholder='Selecione o grau de escolaridade...'
                            name='scholarship'
                            label='Escolaridade'
                            required
                            disabled={readOnlyFlag}
                            values={scholarshipLOV}
                            getChangeValue={e => getGraduation(e)}
                            control={control}
                            errors={errors}
                            width='65%'
                        />
                    </Grid>
                    <Grid item xl={6} lg={6} md={6} sm={12} xs={12} direction="column" >
                        <FormInput
                            name='birthDate'
                            label='Data de nascimento'
                            mask='date'
                            control={control}
                            errors={errors}
                            disabled={readOnlyFlag}
                        />
                        {/* <FormDatePicker
                            name='birthDate'
                            label='Data de nascimento'
                            required
                            control={control}
                            errors={errors}
                            disabled={readOnlyFlag}
                        /> */}
                        <FormInput
                            name='rg'
                            label='RG'
                            control={control}
                            errors={errors}
                            disabled={readOnlyFlag}
                        />
                        <FormInput
                            name='graduationCourse'
                            label='Curso superior'
                            control={control}
                            errors={errors}
                            disabled={readOnlyFlag || !isGraduate}
                        />
                    </Grid>
                </Grid>

                <hr style={{ marginTop: '2em' }} />

                <Grid item xl={6} lg={6} md={6} sm={12} xs={12} >
                    <FormInput
                        name='cep'
                        label='CEP'
                        mask='cep'
                        required
                        control={control}
                        errors={errors}
                        getChangeValue={e => getCepChange(e)}
                        disabled={readOnlyFlag}
                    />
                </Grid>
                <Grid container direction="row" justify="flex-start" alignItems="flex-start" spacing={1}>
                    <Grid item xl={6} lg={6} md={6} sm={12} xs={12} direction="column" >
                        <FormInput
                            name='street'
                            label='Rua'
                            required
                            control={control}
                            errors={errors}
                            disabled={readOnlyFlag}
                        />
                        <FormInput
                            name='district'
                            label='Bairro'
                           required
                            control={control}
                            errors={errors}
                            disabled={readOnlyFlag}
                        />
                        <FormDropdown
                            placeholder='Pesquisa o estado...'
                            name='state'
                            label='Estado'
                            required
                            values={statesList}
                            control={control}
                            errors={errors}
                            getChangeValue={e => getCitiesByStateId(e)}
                            width='65%'
                            disabled={readOnlyFlag}
                        />
                    </Grid>
                    <Grid item xl={6} lg={6} md={6} sm={12} xs={12} direction="column" >
                        <FormInput
                            name='number'
                            label='Número'
                            type='number'
                            required
                            control={control}
                            errors={errors}
                            disabled={readOnlyFlag}
                        />
                        <FormInput
                            name='complement'
                            label='Complemento'
                            control={control}
                            errors={errors}
                            disabled={readOnlyFlag}
                        //maxLength
                        />
                        <FormDropdown
                            placeholder='Pesquisa a cidade...'
                            name='city'
                            label='Cidade'
                           required
                            control={control}
                            errors={errors}
                            values={citiesList}
                            width='65%'
                            disabled={readOnlyFlag}
                        />
                    </Grid>
                </Grid>

                <hr style={{ marginTop: '2em' }} />
                <Text fontWeight='bold' padding={{ top: '1em', bottom: '1em' }}>Informações sobre o contrato</Text>
                <Grid container direction="row" justify="flex-start" alignItems="flex-start" spacing={1}>
                  <Grid item xl={6} lg={6} md={6} sm={12} xs={12} direction="column" >
                      <FormDropdown
                          placeholder='Selecione a carga horária...'
                          name='workingSchedule'
                          label='Carga horária semanal'
                         required
                          values={workingScheduleLOV}
                          control={control}
                          errors={errors}
                          width='65%'
                          disabled={readOnlyFlag}
                      />
                  </Grid>
                  <Grid item xl={6} lg={6} md={6} sm={12} xs={12} direction="column" >
                    <FormDatePicker
                        name='workStartTime'
                        label='Horário de início de estágio'
                        control={control}
                        errors={errors}
                        disabled={readOnlyFlag}
                        dateFormat="HH:mm"
                        showTimeSelect
                        showTimeSelectOnly
                    />
                    <button type="button" onClick={() => setValue('workStartTime', '')}>Limpar</button>
                  </Grid>
                </Grid>
                <Grid container direction="row" justify="flex-start" alignItems="flex-start" spacing={1}>

                    <Grid item xl={6} lg={6} md={6} sm={12} xs={12} direction="column" >
                        <FormDropdown
                            placeholder='Pesquisa a escola...'
                            name='school'
                            label='Escola'
                            required
                            values={combos.schoolsList}
                            control={control}
                            errors={errors}
                            width='65%'
                            disabled={readOnlyFlag}
                        />
                        <FormInput
                            name='startDate'
                            label='Data de início do contrato'
                            mask='date'
                            control={control}
                            errors={errors}
                            disabled={readOnlyFlag}
                        />
                        {/* <FormDatePicker
                            name='startDate'
                            label='Data de início do contrato'
                            required
                            control={control}
                            errors={errors}
                            disabled={readOnlyFlag}
                        /> */}
                        <FormInput
                            name='insuranceCompany'
                            label='Empresa do Seguro'
                           required
                            control={control}
                            errors={errors}
                            disabled={readOnlyFlag}
                        />
                        <FormInput
                            name='wage'
                            label='Valor da Bolsa (R$)'
                            type='text'
                           required
                            control={control}
                            errors={errors}
                            defaultValue={'0,00'}
                            changeValue={e => BRLCurrencyConverter(e.target.value)}
                            disabled={readOnlyFlag}
                        />
                        {editFlag &&
                            <FormDropdown
                                placeholder='Selecione um status...'
                                name='status'
                                label='Status'
                                required
                                values={statusLOV}
                                control={control}
                                errors={errors}
                                width='65%'
                                disabled
                            />
                        }

                        {
                          enableRevocationDatePicker &&

                          <FormInput
                            name='revokedAt'
                            label='Data de revogação do contrato'
                            mask='date'
                            control={control}
                            errors={errors}
                            disabled={readOnlyFlag}
                        />

                          // <FormDatePicker
                          //     name='revokedAt'
                          //     label='Data de revogação do contrato'
                          //     required
                          //     control={control}
                          //     errors={errors}
                          //     disabled={readOnlyFlag}
                          // />
                        }

                    </Grid>
                    <Grid item xl={6} lg={6} md={6} sm={12} xs={12} direction="column" >
                        <FormDropdown
                            placeholder='Pesquisa a empresa...'
                            name='company'
                            label='Empresa'
                          required
                            values={combos.companiesList}
                            control={control}
                            errors={errors}
                            width='65%'
                            disabled={readOnlyFlag}
                            getChangeValue={e => getCompanyDefaultSupervisor(e)}
                        />
                        <FormInput
                            name='endDate'
                            label='Data de término do contrato'
                            mask='date'
                            control={control}
                            errors={errors}
                            disabled={readOnlyFlag}
                        />
                        {/* <FormDatePicker
                            name='endDate'
                            label='Data de término do contrato'
                            required
                            control={control}
                            errors={errors}
                            disabled={readOnlyFlag}
                        /> */}
                        <FormInput
                            name='policyNumber'
                            label='Código do seguro'
                            control={control}
                            errors={errors}
                            required
                            disabled={readOnlyFlag}
                        />
                        <FormInput
                            name='supervisorName'
                            label='Nome do supervisor'
                           required
                            control={control}
                            errors={errors}
                            disabled={readOnlyFlag}
                        />
                        {readOnlyFlag && editFlag && getValues('status') === 'revoked' &&
                            <FormDropdown
                                placeholder='Selecione um motivo...'
                                name='revocationReason'
                                label='Motivo da Revogação'
                                values={revocationReasonsList}
                                control={control}
                                errors={errors}
                                width='65%'
                                disabled
                            />
                        }
                    </Grid>
                </Grid>

                <FormSubmit
                    padding={{ top: '2em' }}
                    disabled={readOnlyFlag}
                    label={editFlag ? 'Alterar Contrato' : 'Gerar Contrato'}
                />
            </form>


            {/* <Col width='100%' align={{ horizontal: 'flex-start', vertical: 'center' }}>
                <Text
                    type='title'
                    color={colors.darkGrey}
                >
                    Cadastro de Estágio
                </Text>
            </Col>
            <DatePicker
                label='AAAAAAAAAA'
                value={startDate}
                onChangeValue={(date) => setStartDate(date)}
            />

            <Form
                onFormSubmit={onSubmit}
            >
                <Form.Input
                    placeholder='Caio Cerano Silva'
                    name='username'
                    label='Nome'
                    required
                />
                <Form.Input
                    name='salary'
                    label='Salário'
                    type='currency'
                    mask='salary'
                    required
                />
                <Form.DatePicker
                    name='birthDate'
                    label='Data de nascimento'
                    placeholder='12'
                    required
                />
                <Form.DataArray
                    name='semana'
                    label='Horas por dias da semana'
                    values={[
                        {
                            label: 'segunda-feira', name: 'segunda-feira',
                        },
                        {
                            label: 'terca-feira', name: 'terca-feira'
                        },
                        {
                            label: 'quarta-feira', name: 'quarta-feira'
                        },
                        {
                            label: 'quinta-feira', name: 'quinta-feira'
                        },
                        {
                            label: 'sexta-feira', name: 'sexta-feira'
                        },
                    ]}
                />
                <Form.Input
                    name='cpf'
                    label='CPF'
                    mask='cpf'
                    required
                />
                <Form.Input
                    name='rg'
                    label='RG'
                    mask='rg'
                />
                <Form.DatePicker
                    name='birthDate'
                    label='Data de nascimento'
                    placeholder='12'
                    required
                />
                <Form.Input
                    name='cep'
                    label='CEP'
                    mask='cep'
                    required
                />
                <Form.Input
                    name='street'
                    label='Rua'
                    required
                />
                <Form.Input
                    name='number'
                    label='Número'
                    type='number'
                    required
                />
                <Form.Input
                    name='complement'
                    label='Complemento'
                //maxLength
                />
                <Form.Input
                    name='district'
                    label='Bairro'
                    required
                />
                <Form.Dropdown
                    placeholder='Pesquisa a cidade...'
                    name='city'
                    label='Cidade'
                    required
                    values={[
                        { value: '1', label: 'Sao Paulo' },
                        { value: '2', label: 'Maua city' },
                    ]}
                />
                <Form.Dropdown
                    placeholder='Pesquisa o estado...'
                    name='state'
                    label='Estado'
                    required
                    values={[
                        { value: '1', label: 'Sao Paulo' },
                    ]}
                />
                <Form.Dropdown
                    placeholder='Pesquisa a escola...'
                    name='school'
                    label='Escola'
                    required
                    values={[
                        { value: '1', label: 'FUFABC' },
                    ]}
                />
                <Form.Dropdown
                    placeholder='Pesquisa a empresa...'
                    name='company'
                    label='Empresa'
                    required
                    values={[
                        { value: '1', label: 'FUFABC' },
                    ]}
                />
                <Form.DatePicker
                    name='startDate'
                    label='Data de início do contrato'
                    required
                />
                <Form.DatePicker
                    name='endDate'
                    label='Data de término do contrato'
                    required
                />
                <Form.Input
                    name='salary'
                    label='Salário'
                    type='number'
                    // mask='salary'
                    required
                />
                <Form.Input
                    name='supervisorName'
                    label='Nome do supervisor'
                    required
                />
                <Form.Input
                    name='insurance'
                    label='Seguro'
                    required
                />
                <Form.Input
                    name='insuranceNumber'
                    label='Número do seguro'
                    // type='number'
                    required
                />

                <Form.Submit
                    name='submit'
                />
            </Form> */}
        </PageView>
    )
}

export default ContratoNovo
