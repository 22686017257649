import toast from 'react-hot-toast'
import COMPANY_TYPES from 'redux/types/company'
import api from 'services/api'
import errorHandler from 'utils/errorHandler'

const createCompany = (company, redirectOnSuccess) => {
  return dispatch => {
    let toastId = toast.loading(`Criando nova empresa... `)
    api.put(`company`, company).then(response => {
      dispatch({
        type: COMPANY_TYPES.CREATE,
        company
      })
      toast.success(`Empresa criada com sucesso. `)
      redirectOnSuccess("/menu/cadastro/empresa")
    })
    .catch(err => toast.error(`Erro ao criar empresa: ${errorHandler(err)}`))
    .finally(() => toast.dismiss(toastId))
  }
}

const updateCompany = company => {
  return dispatch => {
    let toastId = toast.loading(`Atualizando empresa... `)
    api.put(`company`, company).then(response => {
      dispatch({
        type: COMPANY_TYPES.UPDATE,
        company
      })
      toast.success(`Empresa atualizada com sucesso. `)
    })
    .catch(err => toast.error(`Erro ao atualizar empresa: ${errorHandler(err)}`))
    .finally(() => toast.dismiss(toastId))
  }
}

const getCompanyById = companyId => {
  return dispatch => {
    let toastId = toast.loading(`Obtendo dados da empresa... `)
    api.get(`company/${companyId}`).then(response => {
      dispatch({
        type: COMPANY_TYPES.GET_BY_ID,
        company: response.data
      })
    })
    .catch(err => toast.error(errorHandler(err)))
    .finally(() => toast.dismiss(toastId))
  }
}

const clearAll = () => {
  return dispatch => {
    dispatch({
      type: COMPANY_TYPES.CLEAR
    })
  }
}

export {createCompany, updateCompany, getCompanyById, clearAll}
