import React, { useState, useCallback, memo } from "react"
import { Text, Col, PageView, Table, Button } from 'components/YeyComponents'
import { colors } from 'utils/StyleUtils'
import api from 'services/api'
import { isActiveLOV, paginationLimit, queryParamsConstructor } from 'utils/Utils'
import { BsThreeDotsVertical } from "react-icons/bs"
import { useHistory } from 'react-router-dom'
import { Menu, MenuItem } from "@material-ui/core"

const Escola = () => {
    const defaultFilterValues = [
        { name: 'schoolName', operator: 'contains', type: 'string' },
        { name: 'isActive', operator: 'eq', type: 'string' },
    ]

    const [newFilterValue, setNewFilterValue] = useState(defaultFilterValues)
    const [limit, setLimit] = useState(paginationLimit)
    const history = useHistory()

    const loadData = (props = {}) => {
        const { skip = 0, sortInfo = "", groupBy = "", filterValue = newFilterValue } = props

        const queryParams = queryParamsConstructor({ limit, skip, sortInfo, groupBy, filterValue })


        return api.get("schools", { params: queryParams }).then(response => {
            const totalCount = response?.data?.total
            return {
                data: response?.data?.schools?.map(c => {
                    return c
                }),
                count: totalCount * 1
            }
        })
    }

    const dataSource = useCallback(loadData, [newFilterValue, limit])

    const schoolActiveRender = useCallback(({ value }) => {
      if (value) return 'Sim'
      else return 'Não'
  }, [])

    const ActionComponent = memo(({ data }) => {
        const [isMenuVisible, setIsMenuVisible] = useState(null)

        const handleClick = (event) => {
            setIsMenuVisible(event?.currentTarget)
        }

        const handleClose = () => {
            setIsMenuVisible(false)
        }

        const editSchool = () => {
            setIsMenuVisible(false)
            history.push(`/menu/cadastro/escola/${data?.id}`)
        }

        return (
            <>
                <div id="action" onClick={handleClick}>
                    <BsThreeDotsVertical size={20} />
                </div>
                <Menu
                    id="simple-menu"
                    anchorEl={isMenuVisible}
                    keepMounted
                    open={Boolean(isMenuVisible)}
                    onClose={handleClose}
                >
                    <MenuItem onClick={editSchool}>Editar Escola</MenuItem>
                </Menu>
            </>
        )
    })

    return (
        <PageView>
            <Col align={{ horizontal: 'flex-start' }} flex={1} height='99%'>
                <Text
                    type='title'
                    color={colors.darkGrey}
                >
                  Escolas
                </Text>
                {/* eslint-disable-next-line react/style-prop-object */}
                <Button style='submit' viewStyle={{ padding: { bottom: '1em' }, align: 'flex-start' }} onClickFunction={() => history.push("/menu/cadastro/escola/novo")}>Adicionar escola</Button>
                <Table
                    title='Escolas cadastradas'
                    data={dataSource}
                    limit={limit}
                    columns={[
                        { name: 'id', header: '#', type: 'number', defaultWidth: 80, defaultVisible: false, defaultFlex: 0.35},
                        { name: 'schoolName', defaultFlex: 5, header: 'Nome', },
                        { name: 'city', defaultFlex: 2, header: 'Cidade', },
                        { name: 'stateAbbr', defaultFlex: 1, header: 'UF', },
                        {
                           name: 'isActive',
                           defaultFlex: 1,
                           header: 'Ativo',
                           filterType: 'select',
                            filterEditorProps: {
                            placeholder: 'Todos',
                            dataSource: isActiveLOV,
                          },
                          render: schoolActiveRender,
                        },
                        {
                            name: 'action',
                            header: '',
                            width: 20,
                            defaultFlex: 0.35,
                            render: ({ data }) => <ActionComponent data={data} />,
                            sortable: false,
                        },
                    ]}
                    defaultFilterValues={defaultFilterValues}
                    sortable
                    pagination={true}
                    onFilterValueChange={setNewFilterValue}
                    onLimitChange={setLimit}
                />
            </Col>
        </PageView>
    )
}

export default Escola
