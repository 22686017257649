/** Texto default do sistema
 * @param {text} children - Texto mostrado na tela.
 * 
 * Styles
 * @param {number, string} width - Largura do texto, valor em porcentagem. Ex: 0.7 ou '60%'.
 * @param {number, string} height - Altura do texto, valor em porcentagem. Ex: 0.2 ou '30%'.
 * @param {string, object} align - Alinhamento do texto, opções: 'flex-start', 'center', 'flex-end'. Se for uma string aplicará nos dois eixos. Se for um objeto pode receber {horizontal: <string>, vertical: <string>} para aplicar dois alinhamentos distintos (default: 'flex-start').
 * @param {string} backgroundColor ADICIONAR - Hex da cor de fundo do texto. Ex: '#FF0066'.
 * @param {object} border - Recebe as propriedades: {width: <number>, color: <string>}.
 * @param {string, number, object} margin - Espaçamento da view do texto, exemplos: '10%', 0.3. Se for uma string aplicará nos 4 cantos. Se for um objeto pode receber {top: <value>, bottom: <value>, left: <value>, right: <value>} para aplicar espaçamentos diferentes.
 * @param {string, number, object} padding - Espaçamento do texto até sua view, exemplos: '20%', 0.45. Se for uma string aplicará nos 4 cantos. Se for um objeto pode receber {top: <value>, bottom: <value>, left: <value>, right: <value>} para aplicar espaçamentos diferentes.
 * @param {number} size - Tamanho da fonte do texto, se não for passado nada, o padrão retornado será 14.
 * @param {string} fontWeight - Aplica o estilo na fonte.
 * @param {string} fontFamily ADICIONAR - Aplica uma nova fonte.
 * @param {string} color - Hex da cor do texto (default: '#000').
**/

import React from 'react'
import { TextStyle, TitleStyle, LabelStyle } from './TextStyle.js'
import { Row } from 'components/YeyComponents'

const Text = (props) => {
    const { children } = props

    switch (props.type) {
        case 'title':
            return (
                <Row align={{horizontal: 'flex-start'}} {...props}>
                    <TitleStyle {...props}>{children}</TitleStyle>
                </Row>
            )

        case 'label':
            return (
                <Row align={{horizontal: 'flex-start'}} {...props}>
                    <LabelStyle {...props}>{children}</LabelStyle>
                </Row>
            )

        default:
            return (
                <Row align={{horizontal: 'flex-start'}} {...props}>
                    <TextStyle {...props}>{children}</TextStyle>
                </Row>
            )
    }
}

export default Text
