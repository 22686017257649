import toast from 'react-hot-toast'
import api from 'services/api'
import errorHandler from 'utils/errorHandler'

const formatAndDownloadFile = (response, docId, filename) => {
    const url = window.URL.createObjectURL(response.data)
    const a = document.createElement('a')
    a.href = url
    a.download = `${filename}_${docId}.pdf`
    a.click()
    a.remove()
    setTimeout(() => window.URL.revokeObjectURL(url), 100)
}

const downloadContractObject = contractId => {
    return dispatch => {
        let toastId = toast.loading(`Baixando contrato de estágio... `)
        api.post(`report`, { reportName: `rpt_contrato_estagio`, params: { id: contractId } }, { responseType: `blob` })
            .then(response => {
                formatAndDownloadFile(response, contractId, `contrato`)
                dispatch({ type: 'CONTRACT_DOWNLOAD' })
                toast.success(`Arquivo baixado com sucesso. `)
            })
            .catch(err => toast.error(`Erro ao baixar: ${errorHandler(err)}`))
            .finally(() => toast.dismiss(toastId))
    }
}

const downloadContractTerminationTerm = contractId => {
    return dispatch => {
        let toastId = toast.loading(`Baixando termo de rescisão... `)
        api.post(`report`, { reportName: `rpt_termo_rescisao`, params: { id: contractId } }, { responseType: `blob` })
            .then(response => {
                formatAndDownloadFile(response, contractId, `termo-rescisao`)
                dispatch({ type: 'TERMINATION_TERM_DOWNLOAD' })
                toast.success(`Arquivo baixado com sucesso. `)
            })
            .catch(err => toast.error(`Erro ao baixar: ${errorHandler(err)}`))
            .finally(() => toast.dismiss(toastId))
    }
}

const downloadContractDismissalLetter = contractId => {
    return dispatch => {
        let toastId = toast.loading(`Baixando carta de aviso... `)
        api.post(`report`, { reportName: `rpt_carta_aviso`, params: { id: contractId } }, { responseType: `blob` })
            .then(response => {
                formatAndDownloadFile(response, contractId, `carta-aviso`)
                dispatch({ type: 'DISMISSAL_LETTER_DOWNLOAD' })
                toast.success(`Arquivo baixado com sucesso. `)
            })
            .catch(err => toast.error(`Erro ao baixar: ${errorHandler(err)}`))
            .finally(() => toast.dismiss(toastId))
    }
}

const downloadCustomerEvaluationForm = (contractId, referencePeriod) => {
    return dispatch => {
        let toastId = toast.loading(`Baixando ficha de avaliação de desempenho... `)
        api.post(`report`, { reportName: `rpt_avaliacao_desempenho`, params: { id: contractId, period: referencePeriod } }, { responseType: `blob` })
            .then(response => {
                formatAndDownloadFile(response, contractId, `avaliacao-desempenho`)
                dispatch({ type: 'EVALUATION_FORM_DOWNLOAD' })
                toast.success(`Arquivo baixado com sucesso. `)
            })
            .catch(err => toast.error(`Erro ao baixar: ${errorHandler(err)}`))
            .finally(() => toast.dismiss(toastId))
    }
}

const downloadCustomerRelations = (companyId) => {
    return dispatch => {
        let toastId = toast.loading(`Baixando lista de estagiários da empresa... `)
        api.post(`report`, { reportName: `rpt_relacao_estagiarios`, params: { companyId: companyId } }, { responseType: `blob` })
            .then(response => {
                formatAndDownloadFile(response, companyId, `relacao-estagiarios`)
                dispatch({ type: 'EVALUATION_FORM_DOWNLOAD' })
                toast.success(`Arquivo baixado com sucesso. `)
            })
            .catch(err => toast.error(`Erro ao baixar: ${errorHandler(err)}`))
            .finally(() => toast.dismiss(toastId))
    }
}

export { downloadContractObject, downloadContractTerminationTerm, downloadContractDismissalLetter, downloadCustomerEvaluationForm, downloadCustomerRelations }
