import DASHBOARD_TYPES from 'redux/types/dashboard'

const INITIAL_STATE = {
  contracts: {
    active: 0,
    awaitingSignature: 0,
    closeExpire: 0
  }
}

export default function dashboardReducer(state = INITIAL_STATE, action = {}) {
  switch (action.type) {
    case DASHBOARD_TYPES.COUNT:
      return {...state, contracts: action.data}
    default:
      return state
  }
}
