import COMPANY_TYPES from 'redux/types/company'

const INITIAL_STATE = {
  id: null,
  companyName: null,
  tradeName: null,
  cnpj: null,
  ie: null,
  zipcode: null,
  street: null,
  number: null,
  complement: null,
  district: null,
  cityId: null,
  stateId: null,
  parentCompanyId: null,
  isActive: null,
  defaultSupervisor: null
}

export default function companyReducer(state = INITIAL_STATE, action = {}) {
  switch (action.type) {
    case COMPANY_TYPES.GET_BY_ID:
      return {...state, ...action.company}
    case COMPANY_TYPES.CLEAR:
      return {...state, ...INITIAL_STATE}
    default:
      return state
  }
}
