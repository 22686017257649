import React, { useRef } from 'react'
import { useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { updateUser } from 'redux/actions/user'
import { FormInput, FormSubmit, PageView, Text } from 'components/YeyComponents'
import { Grid, } from "@material-ui/core"

const Usuario = () => {

    const dispatch = useDispatch()
    const userData = useSelector(state => state.auth)
    const { handleSubmit, control, formState: { errors } } = useForm()
    const password = useRef('')

    const onSubmit = data => {
        let payload = {
            id: userData.id,
            name: data.name,
            email: data.email
        }
        if (data?.password?.length > 0 && data?.password === data?.passwordConf) {
            payload.password = data.password
        }
        dispatch(updateUser(payload))
    }

    return (
        <PageView>
            <Text type='title' padding={{ top: '1em' }}>Meus Dados</Text>
            <hr style={{ marginTop: '2em' }} />

            <form className="form" onSubmit={handleSubmit(onSubmit)}>
                <Grid container direction="row" justify="flex-start" alignItems="flex-start" spacing={1}  >
                    <Grid item xl={6} lg={6} md={6} sm={12} xs={12} direction="column" >
                        <Text padding={{ top: '1em' }} fontWeight="bold">Dados de usuário</Text>
                        <FormInput
                            name='username'
                            label='Nome de usuário'
                            disabled
                            control={control}
                            errors={errors}
                            defaultValue={userData.username}
                        />
                        <FormInput
                            name='name'
                            label='Nome'
                            required
                            control={control}
                            errors={errors}
                            defaultValue={userData.name}
                        />
                        <FormInput
                            name='email'
                            label='E-mail'
                            required
                            control={control}
                            errors={errors}
                            defaultValue={userData.email}
                        />
                    </Grid>
                    <Grid item xl={6} lg={6} md={6} sm={12} xs={12} direction="column" >
                        <Text padding={{ top: '1em' }} fontWeight="bold">Alterar senha</Text>
                        <FormInput
                            type='password'
                            name='password'
                            label='Nova Senha'
                            getChangeValue={(newValue) => password.current = newValue}
                            control={control}
                            errors={errors}
                        />
                        <FormInput
                            type='password'
                            name='passwordConf'
                            label='Confirmar Nova Senha'
                            control={control}
                            errors={errors}
                            validate={{
                              passwordMatch: v => {
                                if(!v) return true
                                return (v && v === password.current)
                              },
                          }}
                        />
                        <FormSubmit label="Salvar" padding={{ top: '2em' }} />
                    </Grid>
                </Grid>
            </form>
        </PageView>
    )
}

export default Usuario
