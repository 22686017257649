import {combineReducers} from 'redux'
import auth from './auth'
import combos from './combos'
import workingSchedule from './workingSchedule'
import school from './school'
import dashboard from './dashboard'
import company from './company'
import user from './user'

export default combineReducers({
  auth, combos, workingSchedule, school, dashboard, company, user
})
