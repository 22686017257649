import React, { useEffect, useState } from 'react'
import { withStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import MuiDialogTitle from '@material-ui/core/DialogTitle'
import MuiDialogContent from '@material-ui/core/DialogContent'
import MuiDialogActions from '@material-ui/core/DialogActions'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'
import Typography from '@material-ui/core/Typography'

const styles = (theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
})

const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    )
})

const DialogContent = withStyles((theme) => ({
    root: {
        padding: theme.spacing(2),
    },
}))(MuiDialogContent)

const DialogActions = withStyles((theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(1),
    },
}))(MuiDialogActions)

const CustomizedDialogs = (props) => {
    const { isVisible, setIsVisible, title, text, buttonText, secondaryButtonText, onClick, secondaryButtonOnClick, secondaryButton, children, disabled } = props
    const [open, setOpen] = useState(false)

    const onClickHandler = () => {
        onClick && typeof onClick === 'function' && onClick()
    }

    const handleClose = () => {
        setOpen(false)
        setIsVisible(false)
    }

    useEffect(() => {
        if (isVisible) setOpen(true)
        else setOpen(false)
    }, [isVisible])

    return (
        <div>
            <Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={open}>
                {title &&
                    <DialogTitle id="customized-dialog-title" onClose={handleClose}>
                        {title}
                    </DialogTitle>
                }
                {text &&
                    <DialogContent dividers>
                        <Typography gutterBottom>
                            {text}
                        </Typography>
                        {text && Array.isArray(text) && text.map(({ string, props }) => {
                            <Typography {...props} gutterBottom>
                                {string}
                            </Typography>
                        })}
                        {children}
                    </DialogContent>
                }

                <DialogActions style={{ alignItems: 'space-around', justifyContent: 'space-around'}}>
                    {secondaryButton &&
                        <Button onClick={secondaryButtonOnClick} color="primary">
                            {secondaryButtonText || 'Cancelar'}
                        </Button>
                    }
                    <Button onClick={onClickHandler} disabled={disabled} color="primary">
                        {buttonText || 'Enviar'}
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}

export default CustomizedDialogs
