/** Texto default do sistema
 * @param {text} children - Texto mostrado na tela.
 *
 * Styles
 * @param {number, string} width - Largura do texto, valor em porcentagem. Ex: 0.7 ou '60%'.
 * @param {number, string} height - Altura do texto, valor em porcentagem. Ex: 0.2 ou '30%'.
 * @param {string, object} align - Alinhamento do texto, opções: 'flex-start', 'center', 'flex-end'. Se for uma string aplicará nos dois eixos. Se for um objeto pode receber {horizontal: <string>, vertical: <string>} para aplicar dois alinhamentos distintos (default: 'flex-start').
 * @param {string} backgroundColor ADICIONAR - Hex da cor de fundo do texto. Ex: '#FF0066'.
 * @param {object} border - Recebe as propriedades: {width: <number>, color: <string>}.
 * @param {string, number, object} margin - Espaçamento da view do texto, exemplos: '10%', 0.3. Se for uma string aplicará nos 4 cantos. Se for um objeto pode receber {top: <value>, bottom: <value>, left: <value>, right: <value>} para aplicar espaçamentos diferentes.
 * @param {string, number, object} padding - Espaçamento do texto até sua view, exemplos: '20%', 0.45. Se for uma string aplicará nos 4 cantos. Se for um objeto pode receber {top: <value>, bottom: <value>, left: <value>, right: <value>} para aplicar espaçamentos diferentes.
 * @param {number} size - Tamanho da fonte do texto, se não for passado nada, o padrão retornado será 14.
 * @param {string} fontWeight - Aplica o estilo na fonte.
 * @param {string} fontFamily ADICIONAR - Aplica uma nova fonte.
 * @param {string} color - Hex da cor do texto (default: '#000').
**/

import React, { useCallback } from 'react'
import { } from './DropdownStyle.js'
import { Row, Col, Text } from 'components/YeyComponents'
import Select, { components } from 'react-select'
import { colors } from 'utils/StyleUtils'

const Dropdown = (props) => {
    const { label, labelProps, multiSelect, menuPortalTarget, maxMenuHeight, menuPosition, closeMenuOnSelect, disabled, mask, maskPlaceholder, readOnly, defaultValue, values, selectedValues, onChangeValue, placeholder, inputRef, minWidth, maxWidth } = props

    const changeValueHandler = (newValue) => {
        onChangeValue && typeof onChangeValue == 'function' && onChangeValue(newValue)
    }

    const NoOptionsMessage = props => {
        return (
            <components.NoOptionsMessage {...props}>
                Nenhum valor disponível
            </components.NoOptionsMessage>
        )
    }

    return (
        <Col display='block' {...props} >
            <Text type='label' {...labelProps} {...props}>{label}</Text>
            <Select
                width='100%'
                // {...props}
                inputRef={inputRef}
                options={values}
                onChange={(newValue) => changeValueHandler(newValue)}
                value={selectedValues}
                isMulti={multiSelect}
                closeMenuOnSelect={closeMenuOnSelect ? closeMenuOnSelect : multiSelect ? true : false}
                placeholder={placeholder || 'Pesquise aqui...'}
                menuPlacement="auto"
                menuPosition={menuPosition}
                maxMenuHeight={220 || maxMenuHeight}
                styles={{
                    option: (provided, state) => ({
                        ...provided,
                        color: state.isSelected ? colors.white : colors.fontColor,
                    }),
                    control: (defaultControlValues) => ({
                        ...defaultControlValues,
                        minWidth: minWidth || '20em',
                        maxWidth: maxWidth || '100%',
                    }),
                    menu: provided => {
                        return { ...provided, zIndex: 999999999999999 }
                    },
                }}
                menuPortalTarget={menuPortalTarget}
                defaultValue={defaultValue}
                isDisabled={disabled}
                components={{ NoOptionsMessage }}
            />
        </Col>
    )
}

export default Dropdown
