import React, { useCallback } from 'react'
import { Row, Col, Text } from 'components/YeyComponents'
import { ButtonDefaultStyle, ButtonSubmitStyle } from './ButtonStyle'
import { colors, setSpacing } from 'utils/StyleUtils'

const Button = (props) => {
    const { children, onClickFunction, style, textProps, viewStyle } = props

    const onClickHandler = () => {
        onClickFunction && typeof onClickFunction == 'function' && onClickFunction()
    }

    const buttonProps = {
        onClick: onClickHandler,
        type: 'button',
        borderStyle: props?.borderStyle,
        margin: { top: '0.5em' },
        padding: { top: '0.5em', bottom: '0.5em', left: '1em', right: '1em' }
    }

    const getButtonStyle = () => {
        switch (style) {
            case 'submit':
                return <ButtonSubmitStyle
                    {...buttonProps}
                >
                    <Text color={colors.white} {...textProps}>
                        {children || 'Enviar'}
                    </Text>
                </ButtonSubmitStyle>

            default:
                return <ButtonDefaultStyle
                    {...buttonProps}
                >
                    <Text {...textProps}>
                        {children || 'Enviar'}
                    </Text>
                </ButtonDefaultStyle>
        }
    }

    return <Row {...viewStyle} >
        {getButtonStyle()}
    </Row>
}

export default Button
