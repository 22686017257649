import styled from "styled-components"
import { colors } from 'utils/StyleUtils'

const TextStyle = styled.p`
    color: ${props => props.color ? props.color : colors.fontColor};
    font-size: ${props => props.size ? props.size : '1.2em'};
    font-weight: ${props => props.fontWeight && props.fontWeight};
    flex-shrink: 1;

    &:after {
        content: ${props => props.required && "' *'"};
        color: ${props => props.required && colors.red};
    }
`

const LabelStyle = styled.p`
    color: ${props => props.color ? props.color : colors.fontColor};
    font-size: ${props => props.size ? props.size : '1.2em'};
    font-weight: ${props => props.fontWeight && props.fontWeight};
    flex-shrink: 1;

`
const TitleStyle = styled.p`
    color: ${props => props.color ? props.color : colors.fontColor};
    font-size: ${props => props.size ? props.size : '1.8em'};
    font-weight: ${props => props.fontWeight && props.fontWeight};
    flex-shrink: 1;
`

export { TextStyle, TitleStyle, LabelStyle }