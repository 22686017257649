import { Button, PageView, Text } from 'components/YeyComponents'
import React from 'react'
import { useHistory } from 'react-router-dom'

const NotFound = () => {
  const history = useHistory()
  return (
    <PageView>
      <Text type='title' padding={{ top: '1em' }}>Página não encontrada</Text>
      <hr style={{ marginTop: '2em' }} />
      <Text padding={{ top: '1em' }}>Por favor, verifique a URL digitada e tente novamente. </Text>
      <Text padding={{ top: '1em' }}>Se você acha que isto não deveria acontecer, tente contatar o suporte para verificar o problema. </Text>
      <hr style={{ marginTop: '2em' }} />
      <br />
      <Button onClickFunction={() => history.push(`/menu/dashboard`)}>Voltar</Button>
    </PageView>
  )
}

export default NotFound
