import { FormControlLabel, Grid, Switch } from "@material-ui/core"
import { FormDropdown, FormInput, FormSubmit, PageView, Text } from "components/YeyComponents"
import React, { useCallback, useEffect, useState } from "react"
import { useForm } from "react-hook-form"
import { useDispatch, useSelector } from "react-redux"
import { useHistory, useParams } from "react-router-dom"
import { loadAllCombos, loadCitiesFromState } from "redux/actions/combos"
import { clearAll, createCompany, getCompanyById, updateCompany } from "redux/actions/company"
import api from 'services/api'
import toast from "react-hot-toast"
import errorHandler from 'utils/errorHandler'

const EmpresaForm = () => {
    const { handleSubmit, control, formState: { errors }, reset, setValue } = useForm()
    const companyData = useSelector(state => state.company)
    const statesList = useSelector(state => state.combos.statesList),
        citiesList = useSelector(state => state.combos.citiesList),
        companiesList = useSelector(state => state.combos.companiesList)
    const dispatch = useDispatch()
    const params = useParams()
    const history = useHistory()

    const [switchActive, setSwitchActive] = useState(false)

    useEffect(() => dispatch(loadAllCombos()), [dispatch])

    useEffect(() => {
        if (params?.companyId) {
            dispatch(getCompanyById(params.companyId))
        } else {
            dispatch(clearAll())
        }
    }, [dispatch, params])

    useEffect(() => {
        if (companyData?.stateId) {
            Object.entries(companyData).forEach(([key, value]) => {
              if(key === 'isActive') {
                setSwitchActive(value)
              }
              setValue(key, value)
            })
            dispatch(loadCitiesFromState(companyData?.stateId))
        } else {
            reset(companyData)
        }
    }, [companyData, setValue, dispatch, reset])

    const getCitiesByStateId = useCallback(state => {
        dispatch(loadCitiesFromState(state.value))
        setValue("stateId", state.value)
    }, [dispatch, setValue])

    const selectCity = useCallback(city => {
        setValue("cityId", city.value)
    }, [setValue])

    const selectParentCompany = useCallback(parentCompany => {
        setValue("parentCompanyId", parentCompany.value)
    }, [setValue])

    const handleSwitch = evt => {
      setSwitchActive(evt.target.checked)
      setValue("isActive", evt.target.checked)
    }

    const onSubmit = data => {
        delete data.createdAt
        delete data.updatedAt
        data.isActive = params?.companyId ? data.isActive : true
        dispatch(params?.companyId ? updateCompany(data) : createCompany(data, history.push))
    }

    const getCepChange = useCallback(state => {
        if (state && state.length === 9) {
            api.get(`common/cep/${state}`)
                .then(async response => {
                    const { data } = response

                    setValue('cep', data?.cep)
                    setValue('complement', data?.complement)
                    setValue('district', data?.district)
                    setValue('stateId', data?.state?.id)
                    await dispatch(loadCitiesFromState(data?.state?.id))
                    setValue('cityId', data?.city?.id)
                    setValue('street', data?.street)
                })
                .catch(err => toast.error(`Erro ao encontrar CEP: ${errorHandler(err)}`))
        }
    }, [dispatch, setValue])

    return (
        <PageView>
            <Text type='title' padding={{ top: '1em' }}>{params?.companyId ? 'Editar' : 'Adicionar'} Empresa</Text>
            <hr style={{ marginTop: '2em' }} />

            <form onSubmit={handleSubmit(onSubmit)} style={{ width: '100%', flex: 1 }}>
                <Grid container direction="row" justify="flex-start" alignItems="flex-start" spacing={1}>
                    <Grid item xl={6} lg={6} md={6} sm={12} xs={12} direction="column" >
                        <FormInput
                            name='companyName'
                            label='Razão Social'
                            control={control}
                            errors={errors}
                            defaultValue={companyData?.companyName}
                            required
                        />
                        <FormInput
                            name='tradeName'
                            label='Nome Fantasia'
                            control={control}
                            errors={errors}
                            defaultValue={companyData?.tradeName}
                        />
                    </Grid>
                    <Grid item xl={6} lg={6} md={6} sm={12} xs={12} direction="column" >
                        <FormInput
                            name='cnpj'
                            label='CNPJ'
                            mask='cnpj'
                            control={control}
                            errors={errors}
                            defaultValue={companyData?.companyName}
                            required
                        />
                        <FormInput
                            name='ie'
                            label='Inscrição Estadual'
                            control={control}
                            errors={errors}
                            defaultValue={companyData?.tradeName}
                        />
                    </Grid>
                </Grid>
                <Grid item xl={6} lg={6} md={12} sm={12} xs={12}>
                    <FormDropdown
                        placeholder='Pesquisa a empresa...'
                        name='parentCompanyId'
                        label='Empresa Matriz'
                        values={new Array(...[{ label: 'A própria', value: null }, ...companiesList.filter(c => Number(c.value) !== Number(params?.companyId))])}
                        control={control}
                        errors={errors}
                        getChangeValue={e => selectParentCompany(e)}
                        width="70%"
                    />
                    <FormInput
                        name='defaultSupervisor'
                        label='Nome do supervisor de estágio'
                        control={control}
                        errors={errors}
                        defaultValue={companyData?.defaultSupervisor}
                    />
                </Grid>

                <hr style={{ marginTop: '2em' }} />
                <Text fontWeight='bold' padding={{ top: '1em', bottom: '1em' }}>Endereço</Text>

                <Grid item xl={6} lg={6} md={12} sm={12} xs={12}>
                    <FormInput
                        name='zipcode'
                        label='CEP'
                        mask='cep'
                        control={control}
                        errors={errors}
                        defaultValue={companyData?.zipcode}
                        getChangeValue={e => getCepChange(e)}
                        required
                    />
                </Grid>
                <Grid container direction="row" justify="flex-start" alignItems="flex-start" spacing={1}>
                    <Grid item xl={6} lg={6} md={6} sm={12} xs={12} direction="column" >
                        <FormInput
                            name='street'
                            label='Logradouro'
                            control={control}
                            errors={errors}
                            defaultValue={companyData?.street}
                            required
                        />
                        <FormInput
                            name='number'
                            label='Número'
                            control={control}
                            errors={errors}
                            defaultValue={companyData?.number}
                            required
                        />
                        <FormDropdown
                            placeholder='Pesquisa o estado...'
                            name='stateId'
                            label='Estado'
                            required
                            values={statesList}
                            control={control}
                            errors={errors}
                            getChangeValue={e => getCitiesByStateId(e)}
                            width="70%"
                        />
                    </Grid>
                    <Grid item xl={6} lg={6} md={6} sm={12} xs={12} direction="column" >
                        <FormInput
                            name='district'
                            label='Bairro/Distrito'
                            control={control}
                            errors={errors}
                            defaultValue={companyData?.number}
                            required
                        />
                        <FormInput
                            name='complement'
                            label='Complemento'
                            control={control}
                            errors={errors}
                            defaultValue={companyData?.street}
                        />
                        <FormDropdown
                            placeholder='Pesquisa a cidade...'
                            name='cityId'
                            label='Cidade'
                            required
                            control={control}
                            errors={errors}
                            values={citiesList}
                            getChangeValue={e => selectCity(e)}
                            width="70%"
                        />
                    </Grid>
                    {
                      params?.companyId &&
                      <Grid item xl={6} lg={6} md={6} sm={12} xs={12} direction="column">
                        <FormControlLabel
                          style={{marginTop: '2em', border: '2px solid black', width: '65%', padding: '1em', borderRadius: '20px'}}
                          label="Empresa Ativa"
                          labelPlacement="end"
                          control={<Switch
                            checked={switchActive}
                            onChange={e => handleSwitch(e)}
                            color="primary"
                            name="isActive"
                            control={control}
                          />}
                        />
                      </Grid>
                    }
                </Grid>
                <FormSubmit label="Salvar" padding={{ top: '2em' }} />
            </form>

        </PageView>
    )
}

export default EmpresaForm
