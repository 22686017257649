import styled from "styled-components"
import { colors, setSpacing, setAlignment } from 'utils/StyleUtils'

export const Row = styled.div`
    display:  ${props => props.display || 'flex'};
    flex: ${props => props.flex && props.flex};
    flex-direction: ${props => props.flexDirection || 'row'};
    float: ${props => props.float && props.float};

    width: ${props => props.width ? props.width : 'auto'};
    min-width: ${props => props.minWidth && props.minWidth};
    max-width: ${props => props.maxWidth && props.maxWidth};

    height: ${props => props.height ? props.height : 'auto'};

    justify-content: ${props => setAlignment(props.align, 'horizontal', 'center')};
    align-items: ${props => setAlignment(props.align, 'vertical', 'flex-start')};

    background-color: ${props => props.backgroundColor ? props.backgroundColor : 'transparent'};

    padding-top: ${props => setSpacing(props.padding, 'top')};
    padding-bottom: ${props => setSpacing(props.padding, 'bottom')};
    padding-left: ${props => setSpacing(props.padding, 'left')};
    padding-right: ${props => setSpacing(props.padding, 'right')};

    margin-top: ${props => setSpacing(props.margin, 'top')};
    margin-bottom: ${props => setSpacing(props.margin, 'bottom')};
    margin-left: ${props => setSpacing(props.margin, 'left')};
    margin-right: ${props => setSpacing(props.margin, 'right')};

`

export const Column = styled.div`
    ${'' /* display:  ${props => props.display ? props.display : 'flex'}; */}
    flex-direction: ${props => props.flexDirection || 'column'};
    flex: ${props => props.flex && props.flex};
    float: ${props => props.float && props.float};
    overflow: ${props => props.overflow ? props.overflow : 'none'};

    width: ${props => props.width ? props.width : 'auto'};
    min-width: ${props => props.minWidth && props.minWidth};
    max-width: ${props => props.maxWidth && props.maxWidth};

    ${'' /* min-height: 1px; */}
    height: ${props => props.height ? props.height : 'auto'};

    text-align: ${props => props.textAlign ? props.textAlign : 'left'};
    justify-content: ${props => setAlignment(props.align, 'vertical', 'center')};
    align-items: ${props => setAlignment(props.align, 'horizontal', 'flex-start')};

    background-color: ${props => props.backgroundColor ? props.backgroundColor : 'transparent'};

    padding-top: ${props => setSpacing(props.padding, 'top')};
    padding-bottom: ${props => setSpacing(props.padding, 'bottom')};
    padding-left: ${props => setSpacing(props.padding, 'left')};
    padding-right: ${props => setSpacing(props.padding, 'right')};

    margin-top: ${props => setSpacing(props.margin, 'top')};
    margin-bottom: ${props => setSpacing(props.margin, 'bottom')};
    margin-left: ${props => setSpacing(props.margin, 'left')};
    margin-right: ${props => setSpacing(props.margin, 'right')};

    ${'' /* box-sizing: border-box;
    &:before,
    &:after {
        content: '';
        display: table;
    }
    &:after {
        clear: both;
    } */}
`
