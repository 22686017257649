import styled from "styled-components"
import { colors } from 'utils/StyleUtils'

const InputStyle = styled.input`
    color: ${props => props.color ? props.color : colors.fontColor};
    font-size: ${props => props.size ? props.size : '1.2em'};
    font-weight: ${props => props.fontWeight && props.fontWeight};
`

const LabelStyle = styled.label`
    color: ${props => props.color ? props.color : colors.fontColor};
    font-size: ${props => props.size ? props.size : '1.2em'};
    font-weight: ${props => props.fontWeight && props.fontWeight};
`

export { InputStyle, LabelStyle }