import USER_TYPES from 'redux/types/user'

const INITIAL_STATE = {
  id: null,
  username: null,
  email: null,
  name: null,
  isActive: null,
  roleId: null
}
export default function userReducer(state = INITIAL_STATE, action = {}) {
  switch (action.type) {
    case USER_TYPES.LOAD_USER:
      return {...state, ...action.user}
    case USER_TYPES.CLEAR:
      return {...state, ...INITIAL_STATE}
    default:
      return state
  }
}
