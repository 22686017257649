import { Button, Card, CardContent, Grid, Typography } from "@material-ui/core"
import { PageView, Text } from "components/YeyComponents"
import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useHistory } from "react-router-dom"
import { countContractsForDashboard } from "redux/actions/dashboard"
import addDaysAndFormatDate from "utils/addDaysAndFormatDate"
import Chart from 'react-apexcharts'

const DashboardChart = ({data}) => {
  const chartOptions = {
    chart: {
      type: 'donut',
      width: 1000,
      offsetX: 20
    },
    labels: ['Ativos', 'Aguardando Assinatura', 'Próximos do Vencimento'],
    responsive: [{
      breakpoint: 1200,
      options: {
        chart: {
          width: 500,
          offsetX: 60
        },
        legend: {
          position: 'bottom',
          offsetX: 0,
          fontSize: '15px',
          markers: {
            offsetX: 0,
            offsetY: 0
          },
        }
      }
    }],
    legend: {
      position: 'right',
      horizontalAlign: 'left',
      fontSize: '20px',
      offsetX: -40,
      markers: {
        offsetX: -10,
        offsetY: 0
      },
    }
  }
  const [chartSeries, setChartSeries] = useState(Object.values(data).map(v => v))

  useEffect(() => {
    if(data && typeof data === 'object') {
      setChartSeries(Object.values(data).map(v => v))
    }
  }, [data])

  return (
    <Chart options={chartOptions} series={chartSeries} type="donut" width="800"/>
  )
}

const Dashboard = () => {
  const contracts = useSelector(state => state?.dashboard?.contracts)
  const dispatch = useDispatch()
  const history = useHistory()

  useEffect(() => dispatch(countContractsForDashboard()), [dispatch])

  return (
    <PageView>
      <Text type='title' padding={{ top: '1em' }}>Dashboard</Text>
      <hr style={{ marginTop: '2em' }} />
      <Text type='label' padding={{ top: '1em' }}>Situação dos Contratos</Text>
      <br />
      <Grid container spacing={3} alignItems="center" justify="center">
        <Grid item xl={4} lg={4} md={12} sm={12} xs={12}>
          <Card className="root" variant="outlined" style={{textAlign: 'center', width: '100%'}}>
            <CardContent>
              <Typography className="title" color="textSecondary" gutterBottom>
                Ativos
              </Typography>
              <Typography variant="h3" component="h2">
              {contracts?.active}
              </Typography>
              <Button size="small" onClick={() => history.push({pathname: '/menu/estagio/contratos', state: {defaultStatusValue: 'active'}})}>Ver contratos</Button>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xl={4} lg={4} md={12} sm={12} xs={12}>
          <Card className="root" variant="outlined" style={{textAlign: 'center', width: '100%'}}>
            <CardContent>
              <Typography className="title" color="textSecondary" gutterBottom>
                Aguardando Assinatura
              </Typography>
              <Typography variant="h3" component="h2">
              {contracts?.awaitingSignature}
              </Typography>
              <Button size="small" onClick={() => history.push({pathname: '/menu/estagio/contratos', state: {defaultStatusValue: 'awaiting_signature'}})}>Ver contratos</Button>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xl={4} lg={4} md={12} sm={12} xs={12}>
          <Card className="root" variant="outlined" style={{textAlign: 'center', width: '100%'}}>
            <CardContent>
              <Typography className="title" color="textSecondary" gutterBottom>
                Próximos a Vencer (30 dias)
              </Typography>
              <Typography variant="h3" component="h2">
              {contracts?.closeExpire}
              </Typography>
              <Button size="small" onClick={() => history.push({pathname: '/menu/estagio/contratos', state: {defaultStatusValue: 'active', defaultEndDateValue: addDaysAndFormatDate()}})}>Ver contratos</Button>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xl={8} lg={9} md={12} sm={12} xs={12}>
          <Card className="root" variant="outlined" style={{ width: '100%'}}>
            <CardContent>
              <DashboardChart data={contracts}/>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </PageView>
  )
}

export default Dashboard
