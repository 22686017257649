import styled, { css } from "styled-components"
import { colors, setSpacing } from 'utils/StyleUtils'

const baseButtonStyle = css`
    color: ${props => props.color ? props.color : colors.fontColor};
    font-size: ${props => props.size ? props.size : '1.05em'};
    font-weight: ${props => props.fontWeight && props.fontWeight};

    border-style: ${props => props.borderStyle ? props.borderStyle : 'solid'};
    border-width: ${props => props.borderWidth ? props.borderWidth : '1px'};
    border-color: ${props => props.borderColor ? props.borderColor : colors.grey};

    margin-top: ${props => setSpacing(props.margin, 'top')};
    margin-bottom: ${props => setSpacing(props.margin, 'bottom')};
    margin-left: ${props => setSpacing(props.margin, 'left')};
    margin-right: ${props => setSpacing(props.margin, 'right')};

    padding-top: ${props => setSpacing(props.padding, 'top')};
    padding-bottom: ${props => setSpacing(props.padding, 'bottom')};
    padding-left: ${props => setSpacing(props.padding, 'left')};
    padding-right: ${props => setSpacing(props.padding, 'right')};

    display:  ${props => props.display || 'flex'};
    flex: ${props => props.flex && props.flex};
    float: ${props => props.float && props.float};

    width: ${props => props.width ? props.width : 'auto'};
    height: ${props => props.height ? props.height : 'auto'};
`
const ButtonDefaultStyle = styled.button`
    ${baseButtonStyle}
`

const ButtonSubmitStyle = styled.button`
    ${baseButtonStyle}
    background-color: ${props => props.backgroundColor ? props.backgroundColor : colors.darkGrey};
    color: ${props => props.fontColor ? props.fontColor : colors.white};
    
    ${'' /* &:focus {
        outline: none;
        box-shadow: 1px 1px 1px 1px ${colors.darkGrey};
    }
    &:active {
        color: ${colors.darkGrey};
        background-color: ${colors.white};
    } */}
`

export { ButtonDefaultStyle, ButtonSubmitStyle }
