/** Texto default do sistema
 * @param {array} data - Array de objetos contendo os valores das colunas. Exemplo: [{ name: '', surname: 'Baran', birthYear: 1987, birthCity: 34 }]
 * @param {array} columns - Array de objetos contendo title e field. Exemplo: [{ title: 'Nome', field: 'name' }, {...}]
 * @param {string} title - Texto com o titulo da tabela
 * Styles
 * @param {number, string} width - Largura do texto, valor em porcentagem. Ex: 0.7 ou '60%'.
 * @param {number, string} height - Altura do texto, valor em porcentagem. Ex: 0.2 ou '30%'.
 * @param {string, object} align - Alinhamento do texto, opções: 'flex-start', 'center', 'flex-end'. Se for uma string aplicará nos dois eixos. Se for um objeto pode receber {horizontal: <string>, vertical: <string>} para aplicar dois alinhamentos distintos (default: 'flex-start').
 * @param {string} backgroundColor ADICIONAR - Hex da cor de fundo do texto. Ex: '#FF0066'.
 * @param {object} border - Recebe as propriedades: {width: <number>, color: <string>}.
 * @param {string, number, object} margin - Espaçamento da view do texto, exemplos: '10%', 0.3. Se for uma string aplicará nos 4 cantos. Se for um objeto pode receber {top: <value>, bottom: <value>, left: <value>, right: <value>} para aplicar espaçamentos diferentes.
 * @param {string, number, object} padding - Espaçamento do texto até sua view, exemplos: '20%', 0.45. Se for uma string aplicará nos 4 cantos. Se for um objeto pode receber {top: <value>, bottom: <value>, left: <value>, right: <value>} para aplicar espaçamentos diferentes.
 * @param {number} size - Tamanho da fonte do texto, se não for passado nada, o padrão retornado será 14.
 * @param {string} fontWeight - Aplica o estilo na fonte.
 * @param {string} fontFamily ADICIONAR - Aplica uma nova fonte.
 * @param {string} color - Hex da cor do texto (default: '#000').
**/

import React, { forwardRef, useState, useEffect } from 'react'
import { Row, Col, Input, Text } from 'components/YeyComponents'
import { colors } from 'utils/StyleUtils'
import './Table.css'

import MaterialTable, { MTableToolbar } from 'material-table'
//import ReactDataGrid from 'react-data-grid'
// import createRowData from './createRowData';


import ReactDataGrid from '@inovua/reactdatagrid-community'
import '@inovua/reactdatagrid-community/index.css'
import CheckBox from '@inovua/reactdatagrid-community/packages/CheckBox'
import NumberFilter from '@inovua/reactdatagrid-community/NumberFilter'
import SelectFilter from '@inovua/reactdatagrid-community/SelectFilter'
import DateFilter from '@inovua/reactdatagrid-community/DateFilter'
import '@inovua/reactdatagrid-community/theme/default-light.css'
// import '@inovua/reactdatagrid-community/index.css'
// import '@inovua/reactdatagrid-community/base.css'

// Imports dos icones utilizados na tabela
import AddBox from '@material-ui/icons/AddBox'
import ArrowDownward from '@material-ui/icons/ArrowDownward'
import Check from '@material-ui/icons/Check'
import ChevronLeft from '@material-ui/icons/ChevronLeft'
import ChevronRight from '@material-ui/icons/ChevronRight'
import Clear from '@material-ui/icons/Clear'
import DeleteOutline from '@material-ui/icons/DeleteOutline'
import Edit from '@material-ui/icons/Edit'
import FilterList from '@material-ui/icons/FilterList'
import FirstPage from '@material-ui/icons/FirstPage'
import LastPage from '@material-ui/icons/LastPage'
import Remove from '@material-ui/icons/Remove'
import SaveAlt from '@material-ui/icons/SaveAlt'
import Search from '@material-ui/icons/Search'
import ViewColumn from '@material-ui/icons/ViewColumn'

const Table = (props) => {
    const { data, columns, rowStyle, onReady, handle, defaultFilterValues, defaultLimit, defaultSortInfo, sortable, title, getValue, editable, limit, pagination, onLimitChange, renderRowContextMenu, showColumnMenuTool, showEmptyRows, onEditComplete } = props

    const [columnsHandler, setColumnsHandler] = useState([])

    useEffect(() => {
        let columnsArray = []
        if (columns && Array.isArray(columns)) {
            columnsArray = columns.map((columnValue) => {
                switch (columnValue?.filterType?.toLowerCase()) {
                    case 'checkbox':
                        return { ...columnValue, filterEditor: CheckBox }
                    case 'date':
                        return { ...columnValue, filterEditor: DateFilter }
                    case 'select':
                        return { ...columnValue, filterEditor: SelectFilter }
                    case 'number':
                        return { ...columnValue, filterEditor: NumberFilter }
                    default:
                        return { ...columnValue }
                }
            })
        }
        setColumnsHandler(columnsArray)
    }, [columns])

    const tableIcons = {
        Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
        Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
        Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
        Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
        DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
        Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
        Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
        Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
        FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
        LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
        NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
        PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
        ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
        Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
        SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
        ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
        ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
    }


    const emptyText = <b style={{}}>Nenhum valor encontrado</b>
    const i18n = Object.assign({}, ReactDataGrid.defaultProps.i18n, {
        sortAsc: 'Ordem ascendente',
        sortDesc: 'Ordem descendente',
        clear: 'Remover',
        clearAll: 'Remover todos',
        inlist: 'Listados',
        inrange: 'No alcance',
        notinrange: 'Fora do alcance',
        notinlist: 'Não listados',
        eq: 'Iguais a',
        neq: 'Diferentes de',
        gt: 'Maior que',
        gte: 'Maior ou igual a',
        lt: 'Menor que',
        lte: 'Menor ou igual a',
        disable: 'Desabilitar',
        enable: 'Habilitar',
        after: 'Depois',
        afterOrOn: 'Depois ou dentro',
        empty: 'Vazio',
        notEmpty: 'Preenchido',
        startsWith: 'Começa com',
        endsWith: 'Termina com',
        contains: 'Contém',
        notContains: 'Não contém',
        before: 'Antes',
        beforeOrOn: 'Antes ou dentro',
        perPageText: 'Resultados por página',
        showingText: 'Exibindo ',
        ofText: ' de ',
        pageText: 'Página ',
        columns: 'Colunas',
        showFilteringRow: 'Mostrar filtro',
        hideFilteringRow: 'Esconder filtro',
        lockStart: 'Trancar início',
        lockEnd: 'Trancar final',
        unsort: 'Remover ordenação',
        renderLoadMask: {
            loadingText: 'Carregando...',
        },
        loadingText: 'Carregando...',

    })


    const filterTypes = Object.assign({}, ReactDataGrid.defaultProps.filterTypes, {
        string: {
            name: 'string',
            emptyValue: null,
            operators: [
                {
                    name: 'contains',
                    fn: ({ value, filterValue, data }) => {
                        return filterValue != null ? filterValue === value : true;
                    }
                },
                // {
                //     name: 'neq',
                //     fn: ({ value, filterValue, data }) => {
                //         return filterValue != null ? filterValue !== value : true;
                //     }
                // }
            ]
        }
    })

    return (
        <div style={{ flex: 1, width: '100%', flexGrow: 1, }}>
            {title && <Text align={{ horizontal: 'flex-start' }}>{title}</Text>}

            {/* <Input style={{ marginBottom: 20, padding: 5, width: 250 }} type='text' autoFocus defaultValue='Hit tab to navigate to grid' /> */}
            <ReactDataGrid
                idProperty='id'
                // style={{ minHeight: 250 }}
                style={{ minHeight: '55em', flex: 1, flexGrow: 1, overflow: 'visible' }}
                columns={columnsHandler}
                dataSource={data}
                filterTypes={filterTypes}
                defaultFilterValue={defaultFilterValues}
                defaultSortInfo={defaultSortInfo}
                emptyText={emptyText}
                i18n={i18n}
                sortable={sortable}
                showCellBorders={true}
                limit={limit}
                pagination={pagination}
                onLimitChange={onLimitChange}
                renderRowContextMenu={renderRowContextMenu}
                showColumnMenuTool={showColumnMenuTool}
                showEmptyRows={typeof showEmptyRows !== 'undefined' ? showEmptyRows : true}
                loadingText={'Carregando...'}
                rowStyle={rowStyle}
                minRowHeight={50}
                rowHeight={null}
                onEditComplete={onEditComplete}
                //editable={editable}
                onReady={onReady}
                pageSizes={[5, 10, 15, 20, 40, 80]}
                defaultLimit={defaultLimit || 15}
                columnUserSelect={true}
                handle={handle}
            />
            {/* <Input style={{ marginTop: 20, padding: 5, width: 250 }} type='text' defaultValue='Hit shift+tab to navigate to grid' /> */}
        </div>
    )

}

export default Table
