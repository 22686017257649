import React from "react"
import { Switch } from "react-router-dom"

import DefaultRoute from "routes/defaultRoute"

import Contrato from "pages/private/estagio/contrato"
import ContratoForm from "pages/private/estagio/contrato/novo"

const ContratoRoutes = ({match}) => {
  const PARENT_PATH = match.url || ""
  return (
    <Switch>
      <DefaultRoute exact path={PARENT_PATH} component={Contrato} />
      <DefaultRoute exact path={PARENT_PATH + "/novo"} component={ContratoForm} />
      <DefaultRoute exact path={PARENT_PATH + "/:contractId"} component={ContratoForm} />
      <DefaultRoute />
    </Switch>
  )
}

export default ContratoRoutes