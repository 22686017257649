import React, { useState, useRef, useCallback, useEffect, memo } from "react"
import { Text, Col, Button, PageView, Table, Input, Dropdown, Dialog, DatePicker } from 'components/YeyComponents'
import { colors } from 'utils/StyleUtils'
import api from 'services/api'
import { statusLOV, paginationLimit, queryParamsConstructor } from 'utils/Utils'
import { BsThreeDotsVertical } from "react-icons/bs"
import { useHistory, useLocation } from 'react-router-dom'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import { downloadContractObject, downloadCustomerEvaluationForm, downloadContractTerminationTerm, downloadContractDismissalLetter, } from 'redux/actions/report'
import { useDispatch, useSelector } from "react-redux"
import { loadAllCombos, } from "redux/actions/combos"
import toast from 'react-hot-toast'
import errorHandler from 'utils/errorHandler'

const Contrato = () => {
    const location = useLocation()
    const combos = useSelector(state => state.combos)
    const defaultFilterValues = [
        { name: 'id', operator: 'contains', type: 'string' },
        { name: 'customerName', operator: 'contains', type: 'string' },
        { name: 'companyName', operator: 'contains', type: 'string' },
        { name: 'schoolName', operator: 'contains', type: 'string' },
        { name: 'startDate', operator: 'eq', type: 'string' },
        { name: 'endDate', operator: 'eq', type: 'string', value: location?.state?.defaultEndDateValue },
        { name: 'status', operator: 'eq', type: 'string', value: location?.state?.defaultStatusValue },
    ]
    const [newFilterValue, setNewFilterValue] = useState(defaultFilterValues)
    const [limit, setLimit] = useState(paginationLimit)
    const [gridRef, setGridRef] = useState(null)

    const [isEvaluationDialogVisible, setIsEvaluationDialogVisible] = useState(false)
    const [isRevocationDialogVisible, setIsRevocationDialogVisible] = useState(false)
    const [isStatusDialogVisible, setIsStatusDialogVisible] = useState(false)

    let selectedContractId = useRef()
    let selectedContractValue = useRef({ data: null, index: null, status: null })

    const history = useHistory()
    const dispatch = useDispatch()

    const loadData = (props = {}) => {
        const { skip = 0, sortInfo = "", groupBy = "", filterValue = newFilterValue } = props

        const queryParams = queryParamsConstructor({ limit, skip, sortInfo, groupBy, filterValue })

        return api.get("contracts", { params: queryParams }).then(response => {
            const totalCount = response.data.total
            return {
                data: response?.data?.contracts?.map(c => {
                    c.wage = Number(c.wage).toLocaleString("pt-BR", { minimumFractionDigits: 2 })
                    c.status = c.status.label
                    c.startDate = new Date(c?.startDate).toLocaleDateString('pt-BR')
                    c.endDate = new Date(c?.endDate).toLocaleDateString('pt-BR')

                    return c
                }),
                count: totalCount * 1
            }
        })
    }

    const dataSource = useCallback(loadData, [newFilterValue, limit])

    const ActionComponent = memo(({ data }) => {
        const [isMenuVisible, setIsMenuVisible] = useState(null)

        const handleClick = (event) => {
            setIsMenuVisible(event?.currentTarget)
        }

        const handleClose = () => {
            setIsMenuVisible(false)
        }

        const downloadContract = () => {
            setIsMenuVisible(false)
            dispatch(downloadContractObject(data?.id))
        }

        const editContract = () => {
            setIsMenuVisible(false)
            // history.push(`contratos/editar/${data?.id}`)
            history.push({ pathname: `contratos/${data?.id}` })
        }

        const generateReport = () => {
            selectedContractId.current = data?.id
            setIsMenuVisible(false)
            setIsEvaluationDialogVisible(true)
        }

        const generateContractTerminationTerm = () => {
            setIsMenuVisible(false)
            dispatch(downloadContractTerminationTerm(data?.id))
        }

        const generateContractDismissalLetter = () => {
            setIsMenuVisible(false)
            dispatch(downloadContractDismissalLetter(data?.id))
        }

        const generateContractDismissalAndTermination = () => {
            setIsMenuVisible(false)
            dispatch(downloadContractDismissalLetter(data?.id))
            dispatch(downloadContractTerminationTerm(data?.id))
        }

        let revokedStatusMenu = [
            <MenuItem onClick={generateContractDismissalLetter}>Carta de Aviso</MenuItem>,
            <MenuItem onClick={generateContractTerminationTerm}>Termo de Rescisão</MenuItem>,
            <MenuItem onClick={generateContractDismissalAndTermination}>Rescisão/Aviso</MenuItem>,
        ]

        return (
            <>
                <div id="action" onClick={handleClick}>
                    <BsThreeDotsVertical size={20} />
                </div>
                <Menu
                    id="simple-menu"
                    anchorEl={isMenuVisible}
                    keepMounted
                    open={Boolean(isMenuVisible)}
                    onClose={handleClose}
                >
                    <MenuItem onClick={downloadContract}>Baixar Contrato</MenuItem>
                    <MenuItem onClick={editContract}>Editar Contrato</MenuItem>
                    <MenuItem onClick={generateReport}>Gerar Ficha de Avaliação</MenuItem>
                    {
                        data?.status === 'Rescindido' && revokedStatusMenu.map((value) => {
                            return value
                        })
                    }
                </Menu>
            </>
        )
    })

    const StatusDropdownComponent = memo(({ data, columnIndex, rowIndex }) => {
        const [selectedStatus, setSelectedStatus] = useState({})
        const [statusData, setStatusData] = useState([])

        const refMainView = useRef()

        useEffect(() => {
            if (data?.status) {
                const selectedStatusObject = statusLOV.find(element => element?.label === data?.status)
                if (selectedStatusObject) {
                    setSelectedStatus(selectedStatusObject)
                }
            }
        }, [data])

        useEffect(() => {
            let statusDataArray = []
            if (selectedStatus?.acceptedValues && Array.isArray(selectedStatus?.acceptedValues) && selectedStatus?.acceptedValues?.length > 0) {
                selectedStatus?.acceptedValues?.forEach(acceptedStatusValue => {
                    const foundStatus = statusLOV.find(({ value }) => value === acceptedStatusValue)
                    statusDataArray.push(foundStatus)
                })
            }
            setStatusData(statusDataArray)
        }, [selectedStatus])

        const onChangeStatus = (newValue) => {
            selectedContractValue.current = { ...selectedContractValue.current, index: rowIndex, status: newValue }
            selectedContractId.current = data?.id

            if (newValue?.value !== selectedStatus?.value) {
                if (newValue?.value !== 'revoked') {
                    setIsStatusDialogVisible(true)
                }
                else setIsRevocationDialogVisible(true)
            }
        }

        //console.log('gridRef', gridRef?.current)
        //console.log('bodyRef grid', gridRef?.current?.bodyRef?.current)
        // console.log('bodyRef portalRef', gridRef?.current?.portalRef?.current?.domNode) // nao visivel
        // console.log('bodyRef portalRef', gridRef?.current?.portalRef?.current?.offsetParent) // nao visivel
        // console.log('bodyRef bodyRef', gridRef?.current?.domRef?.current) // nao resolve
        // console.log('bodyRef document', document.body)
        // parentElement
        // parentNode

        return (
            <div id='divR' ref={refMainView}>
                {selectedStatus && gridRef?.current?.bodyRef?.current &&
                    <Dropdown
                        width='100%'
                        // values={statusLOV} // Testes para mudanca de status
                        values={statusData}
                        // selectedValues={
                        //     statusData.filter(option =>
                        //         option.label === data?.status)
                        // }
                        selectedValues={[selectedStatus]}
                        onChangeValue={onChangeStatus}
                        maxWidth='100%'
                        minWidth='100%'
                        flex={1}
                        closeMenuOnSelect={true}
                        menuPortalTarget={document.body}
                        menuPosition='fixed'
                    />
                }
            </div>
        )
    })

    const EvaluationDialog = () => {
        const [avaliationInputText, setAvaliationInputText] = useState('')

        const getInputValue = (newValue) => {
            setAvaliationInputText(newValue?.target?.value)
        }

        const onSubmit = () => {
            dispatch(downloadCustomerEvaluationForm(selectedContractId.current, avaliationInputText))
            setIsEvaluationDialogVisible(false)
        }

        return (
            <Dialog
                title='Ficha de Avaliação'
                text='Insira no campo abaixo o periodo que deve ser passado para a ficha de avaliação'
                isVisible={isEvaluationDialogVisible}
                setIsVisible={setIsEvaluationDialogVisible}
                onClick={onSubmit}
                disabled={avaliationInputText.length === 0}
            >
                <Input value={avaliationInputText} onChangeValue={getInputValue} onChange={getInputValue} />
            </Dialog>
        )
    }

    const statusChangeHandler = (revocationReason, revocationDate) => {
        let revokedFlag = revocationReason ? true : false

        let toastId = toast.loading(`Atualizando status do contrato...`)
        const queryConfig = {
            params: {
                status: selectedContractValue.current?.status?.value,
            }
        }

        // Enviado somente em casos onde acontece a rescisao do contrato (Porque o back ta de frescura)
        if (revokedFlag) {
            queryConfig.params.revocationReasonId = revocationReason
            queryConfig.params.revocationDate = revocationDate
        }

        api.patch(`contract/${selectedContractId.current}/status`, {}, queryConfig)
            .then(response => {
                toast.success(`Contrato ${revokedFlag ? 'rescindido' : 'atualizado'} com sucesso. `, response)

                if (revokedFlag) setIsRevocationDialogVisible(false)
                else setIsStatusDialogVisible(false)

                gridRef?.current?.setItemAt(selectedContractValue.current?.index, { ...selectedContractValue.current?.data, status: selectedContractValue.current?.status?.label })
            })
            .catch(err => toast.error(`Erro ao atualizar valor: ${errorHandler(err)}`))
            .finally(() => toast.dismiss(toastId))
    }

    const RevocationDialog = () => {
        const [revocationReason, setRevocationReason] = useState(null)
        const [revocationDate, setRevocationDate] = useState(new Date())

        const onSelectEvocation = (newValue) => {
            setRevocationReason(newValue)
        }

        const onDateSelect = (newDate) => {
            setRevocationDate(newDate)
        }

        const onSubmit = () => {
            statusChangeHandler(revocationReason?.value, revocationDate)
        }

        return (
            <Dialog
                title='Rescisão de contrato'
                text='Preencha abaixo os dados referentes à rescisão do contrato'
                isVisible={isRevocationDialogVisible}
                setIsVisible={setIsRevocationDialogVisible}
                onClick={onSubmit}
                secondaryButtonOnClick={() => setIsRevocationDialogVisible(false)}
                secondaryButton
                buttonText='Alterar'
                disabled={!revocationReason?.value}
            >
                <>
                    <Dropdown
                        label='Motivo da rescisão'
                        width='100%'
                        values={combos?.revocationReasonsList}
                        selectedValues={[revocationReason]}
                        onChangeValue={onSelectEvocation}
                        maxWidth='100%'
                        minWidth='100%'
                        //padding={{ top: '0.5em' }}
                        flex={1}
                        closeMenuOnSelect={true}
                        menuPosition='fixed'
                        labelProps={{
                            padding:{top: '1em', bottom: '0.5em'},
                            size: '1em'
                        }}
                    />
                    <DatePicker
                        label='Data da rescisão'
                        // placeholder={'data de rescisão'}
                        onChangeValue={onDateSelect}
                        value={revocationDate}
                        readOnlyHandler={true}
                        width='100%'
                        //dateFormat={dateFormat}
                        //popperPlacement='top-end'
                        labelProps={{
                            padding:{top: '1em', bottom: '0.5em'},
                            size: '1em'
                        }}
                    />
                </>
            </Dialog>
        )
    }

    const StatusChangeConfirmationDialog = () => {
        const onSubmit = () => {
            statusChangeHandler(null)
        }

        return (
            <Dialog
                title='Mudança de Status'
                text='Deseja alterar o status atual do contrato?'
                isVisible={isStatusDialogVisible}
                setIsVisible={setIsStatusDialogVisible}
                onClick={onSubmit}
                secondaryButtonOnClick={() => setIsStatusDialogVisible(false)}
                secondaryButton
                buttonText='Alterar'
            >
            </Dialog>
        )
    }

    useEffect(() => dispatch(loadAllCombos()), [dispatch])

    return (
        <PageView>
            <Col align={{ horizontal: 'flex-start', vertical: 'flex-start' }} flex={1} height='99%'>
                <Text
                    type='title'
                    color={colors.darkGrey}
                    padding={{ bottom: '1em' }}
                >
                    Contratos cadastrados
                </Text>
                {/* eslint-disable-next-line react/style-prop-object */}
                <Button sptyle='submit' viewStyle={{ padding: { bottom: '1em' }, align: 'flex-start' }} onClickFunction={() => history.push("contratos/novo")}>Adicionar contrato</Button>
                
                <Table
                    data={dataSource}
                    limit={limit}
                    columns={[
                        { name: 'id', header: '#', type: 'number', defaultFlex: 1, defaultVisible: false, },
                        { name: 'customerName', defaultFlex: 4, header: 'Estagiário', },
                        { name: 'companyName', defaultFlex: 4, header: 'Empresa', },
                        { name: 'schoolName', defaultFlex: 4, header: 'Escola', },
                        { name: 'wage', defaultFlex: 2, header: 'Valor da bolsa (R$)', },
                        {
                            name: 'startDate',
                            defaultFlex: 2,
                            filterType: 'date',
                            header: 'Data de início',
                        },
                        {
                            name: 'endDate',
                            defaultFlex: 2,
                            filterType: 'date',
                            header: 'Data de término',
                        },
                        {
                            name: 'status',
                            defaultFlex: 4,
                            filterType: 'select',
                            // multiple: true,
                            // wrapMultiple: false,
                            filterEditorProps: {
                                placeholder: 'Todos',
                                dataSource: statusLOV,//combos?.status
                            },
                            header: 'Status',
                            render: ({ data, columnIndex, rowIndex }) => <StatusDropdownComponent data={data} columnIndex={columnIndex} rowIndex={rowIndex} />
                        },
                        {
                            name: 'action',
                            width: 20,
                            header: '',
                            sortable: false,
                            render: ({ data }) => <ActionComponent data={data} />,
                        },
                    ]}
                    defaultFilterValues={defaultFilterValues}
                    sortable
                    pagination={true}
                    onFilterValueChange={setNewFilterValue}
                    onLimitChange={setLimit}
                    showColumnMenuTool={true}
                    showEmptyRows={true}
                    editable={true}
                    onReady={setGridRef}
                />
            </Col>

            <EvaluationDialog />
            <RevocationDialog />
            <StatusChangeConfirmationDialog />
        </PageView>
    )
}

export default Contrato
