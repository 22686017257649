import toast from 'react-hot-toast'
import COMBO_TYPES from 'redux/types/combos'
import api from 'services/api'
import errorHandler from 'utils/errorHandler'

const loadAllCombos = () => {
  return dispatch => {
    api.get("common/combos").then(response => {
      dispatch({
        type: COMBO_TYPES.LOAD_ALL,
        data: response.data
      })
    })
    .catch(err => toast.error(errorHandler(err)))
  }
}

const loadCitiesFromState = stateId => {
  return dispatch => {
    api.get(`common/cities`, {params: {stateId}}).then(response => {
      dispatch({
        type: COMBO_TYPES.LOAD_CITIES_FROM_STATE,
        citiesList: response.data
      })
    })
  }
}

export {loadAllCombos, loadCitiesFromState}
