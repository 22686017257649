import toast from 'react-hot-toast'
import AUTH_TYPES from 'redux/types/auth'
import USER_TYPES from 'redux/types/user'
import api from 'services/api'
import { extractDataFromJWTToken, setToken } from 'services/token'
import errorHandler from 'utils/errorHandler'

const createUser = (user, redirectOnSuccess) => {
  return dispatch => {
    let toastId = toast.loading(`Criando usuário... `)

    api.post("user", {...user, roleId: 1}).then(response => {
      dispatch({
        type: USER_TYPES.CREATE_USER,
      })
      toast.success(`Usuário criado com sucesso. `)
      redirectOnSuccess("/menu/cadastro/usuario")
    })
    .catch(err => toast.error(errorHandler(err)))
    .finally(() => toast.dismiss(toastId))
  }
}

const updateUser = data => {
  return dispatch => {
    let toastId = toast.loading(`Atualizando dados de usuário... `)
    const {id, password, name, email} = data
    api.patch("user/self", {id, name, email, password}).then(response => {
      setToken(response.data.token)
      dispatch({
        type: AUTH_TYPES.UPDATE_USERDATA,
        userData: extractDataFromJWTToken(response.data.token)
      })
      toast.success(`Dados atualizados com sucesso. `)
    })
    .catch(err => toast.error(errorHandler(err)))
    .finally(() => toast.dismiss(toastId))
  }
}

const updateUserOther = data => {
  return dispatch => {
    let toastId = toast.loading(`Atualizando dados de usuário... `)
    api.patch("user", data).then(response => {
      dispatch({
        type: USER_TYPES.UPDATE_USER
      })
      toast.success(`Dados atualizados com sucesso. `)
    })
    .catch(err => toast.error(errorHandler(err)))
    .finally(() => toast.dismiss(toastId))
  }
}

const getUser = userId => {
  return dispatch => {
    let toastId = toast.loading(`Obtendo dados do usuário... `)
    api.get(`user/${userId}`).then(response => {
      dispatch({
        type: USER_TYPES.LOAD_USER,
        user: response.data
      })
    })
    .catch(err => toast.error(errorHandler(err)))
    .finally(() => toast.dismiss(toastId))
  }
}

const clearAll = () => {
  return dispatch => {
    dispatch({
      type: USER_TYPES.CLEAR
    })
  }
}

export {updateUser, createUser, getUser, clearAll, updateUserOther}
