import { getToken, isValidJWT, extractDataFromJWTToken } from "services/token"
import store from 'redux/store'
import AUTH_TYPES from 'redux/types/auth'

const hasAuthenticated = () => isValidJWT(getToken())
if(hasAuthenticated() === true) {
  store.dispatch({
    type: AUTH_TYPES.LOGIN,
    data: extractDataFromJWTToken(getToken())
  })
}

export default hasAuthenticated
