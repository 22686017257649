import React from "react"
import { Switch } from "react-router-dom"

import DefaultRoute from "routes/defaultRoute"

import Empresa from "pages/private/cadastro/empresa"
import EmpresaForm from "pages/private/cadastro/empresa/form"


const EmpresaRoutes = ({match}) => {
  const PARENT_PATH = match.url || ""
  return (
    <Switch>
      <DefaultRoute exact path={PARENT_PATH} component={Empresa} />
      <DefaultRoute exact path={PARENT_PATH + "/novo"} component={EmpresaForm} />
      <DefaultRoute exact path={PARENT_PATH + "/:companyId"} component={EmpresaForm} />
      <DefaultRoute />
    </Switch>
  )
}

export default EmpresaRoutes
