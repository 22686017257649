import "./App.css"
import Routes from "routes"
import { Provider } from "react-redux"
import store from 'redux/store'
import { createBrowserHistory } from "history"
import { setupInterceptors } from "services/api"
import { Router } from "react-router-dom"
import { useEffect } from "react"
import { MainView } from "utils/StyleUtils"
import { ChakraProvider } from "@chakra-ui/react"
import toast, { Toaster, ToastBar } from "react-hot-toast"

const history = createBrowserHistory()
setupInterceptors(store, history)

const App = () => {

    useEffect(() => {
        window.addEventListener("resize", () => {
            console.log('resize')
            // if (window.innerWidth <= 767) {
            //     setIsNavOpen(false)
            // }
            // else if (window.innerWidth >= 767) {
            //     setIsNavOpen(true)
            // }
        })
    })

    return (
        <Provider store={store}>
            <Router history={history}>
                <MainView>
                    <ChakraProvider>
                        <Routes />
                        <Toaster>
                            {(t) => (
                                <ToastBar toast={t}>
                                    {({ icon, message }) => (
                                        <>
                                            {icon}
                                            {message}
                                            {t.type !== 'loading' && (
                                                <button onClick={() => toast.dismiss(t.id)}>X</button>
                                            )}
                                        </>
                                    )}
                                </ToastBar>
                            )}
                        </Toaster>
                    </ChakraProvider>
                </MainView>
            </Router>
        </Provider>
    )
}

export default App
