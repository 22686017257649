import { FormControlLabel, Grid, Switch } from "@material-ui/core"
import { FormInput, FormSubmit, PageView, Text } from "components/YeyComponents"
import React, { useEffect, useRef, useState } from "react"
import { useForm } from "react-hook-form"
import { useDispatch, useSelector } from "react-redux"
import { useHistory, useParams } from "react-router-dom"
import { getUser, clearAll, createUser, updateUserOther } from "redux/actions/user"

const UsuarioForm = () => {
  const {handleSubmit, control, formState: { errors }, reset, setValue } = useForm()
  const userData = useSelector(state => state.user)
  const [switchActive, setSwitchActive] = useState(false)
  const password = useRef('')
  const dispatch = useDispatch()
  const params = useParams()
  const history = useHistory()

  useEffect(() => {
    if(params?.userId) {
      dispatch(getUser(params.userId))
    } else {
      dispatch(clearAll())
    }
  }, [dispatch, params])

  useEffect(() => {
    if(userData?.id) {
      Object.entries(userData).forEach(([key, value]) => {
        if(key === 'isActive') {
          setSwitchActive(value)
        }
        setValue(key, value)
      })
    } else {
      reset(userData)
    }
  }, [userData, setValue, dispatch, reset])

  const handleSwitch = evt => {
    setSwitchActive(evt.target.checked)
    setValue("isActive", evt.target.checked)
  }

  const onSubmit = data => {
    delete data.createdAt
    delete data.updatedAt
    data.isActive = params?.userId ? data.isActive : true
    dispatch(params?.userId ? updateUserOther(data) : createUser(data, history.push))
  }

  return (
    <PageView>
      <Text type='title' padding={{ top: '1em' }}>{params?.userId ? 'Editar' : 'Adicionar'} Usuário</Text>
      <hr style={{ marginTop: '2em' }} />

      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container>
          <Grid item xl={6} lg={6} md={12} sm={12} xs={12}>
            <FormInput
                name='username'
                label='Nome de usuário'
                control={control}
                errors={errors}
                defaultValue={userData?.username}
                disabled={userData?.id}
                required
            />
            <FormInput
                name='name'
                label='Nome'
                control={control}
                errors={errors}
                defaultValue={userData?.name}
                required
            />
            <FormInput
                name='email'
                label='E-mail'
                control={control}
                errors={errors}
                defaultValue={userData?.email}
                required
            />
            {
              params?.userId &&
                <FormControlLabel
                  style={{marginTop: '2em', border: '2px solid black', width: '65%', padding: '1em', borderRadius: '20px'}}
                  label="Usuário Ativo"
                  labelPlacement="end"
                  control={<Switch
                    checked={switchActive}
                    onChange={e => handleSwitch(e)}
                    color="primary"
                    name="isActive"
                    control={control}
                  />}
                />
            }
          </Grid>
          <Grid item xl={6} lg={6} md={12} sm={12} xs={12}>
            <FormInput
                name='password'
                type="password"
                label='Senha'
                control={control}
                errors={errors}
                defaultValue={''}
                required={!params?.userId}
                getChangeValue={(newValue) => password.current = newValue}
            />
            <FormInput
                name='passwordConf'
                type="password"
                label='Confirmar Senha'
                control={control}
                errors={errors}
                defaultValue={''}
                required={!params?.userId}
                validate={{
                    passwordMatch: v => {
                      if(!v) return true
                      return (v && v === password.current)
                    },
                }}
            />
            <FormSubmit label="Salvar" padding={{ top: '2em' }} />
          </Grid>
        </Grid>
      </form>
    </PageView>
  )
}

export default UsuarioForm
