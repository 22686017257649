import toast from 'react-hot-toast'
import SCHOOL_TYPES from 'redux/types/school'
import api from 'services/api'
import errorHandler from 'utils/errorHandler'

const createSchool = (school, redirectOnSuccess) => {
  return dispatch => {
    let toastId = toast.loading(`Criando escola... `)
    api.put("school", school).then(response => {
      dispatch({
        type: SCHOOL_TYPES.CREATE_SCHOOL,
      })
      toast.success(`Escola criada com sucesso. `)
      redirectOnSuccess("/menu/cadastro/escola")
    })
    .catch(err => toast.error(`Erro ao criar escola: ${errorHandler(err)}`))
    .finally(() => toast.dismiss(toastId))
  }
}

const updateSchool = school => {
  return dispatch => {
    let toastId = toast.loading(`Atualizando escola... `)
    api.put("school", school).then(response => {
      dispatch({
        type: SCHOOL_TYPES.UPDATE_SCHOOL,
      })
      toast.success(`Escola atualizada com sucesso. `)
    })
    .catch(err => toast.error(`Erro ao atualizar escola: ${errorHandler(err)}`))
    .finally(() => toast.dismiss(toastId))
  }
}

const getSchool = schoolId => {
  return dispatch => {
    let toastId = toast.loading(`Obtendo dados da escola... `)
    api.get(`school/${schoolId}`).then(response => {
      dispatch({
        type: SCHOOL_TYPES.GET_SCHOOL,
        school: response.data
      })
    })
    .catch(err => toast.error(errorHandler(err)))
    .finally(() => toast.dismiss(toastId))
  }
}

const clearAll = () => {
  return dispatch => {
    dispatch({
      type: SCHOOL_TYPES.CLEAR
    })
  }
}

export {createSchool, updateSchool, getSchool, clearAll}
