import React from 'react'
import { Column } from "./LayoutStyle.js"

const PageView = (props) => {
    const { children } = props

    return (
        <Column height='100vh' display='block' padding={{ left: '4em', right: '4em', bottom: '2em', top: '2em' }} overflow='auto' {...props}>
            {children}
        </Column>
    )
}

export default PageView
