import toast from 'react-hot-toast'
import AUTH_TYPES from 'redux/types/auth'
import api from 'services/api'
import { setToken, extractDataFromJWTToken } from 'services/token'
import errorHandler from 'utils/errorHandler'

const signIn = (username, password, history) => {
  return dispatch => {
    let toastId = toast.loading(`Fazendo login... `)
    api.post("login", {username, password}).then(response => {
      setToken(response.data.token)
      dispatch({
        type: AUTH_TYPES.LOGIN,
        data: extractDataFromJWTToken(response.data.token)
      })
      history.push("/menu/dashboard")
      toast.success(`Login realizado com sucesso. `)
    }).catch(err => toast.error(errorHandler(err)))
    .finally(() => toast.dismiss(toastId))
  }
}

const signOut = history => {
  return dispatch => {
    dispatch({type: AUTH_TYPES.LOGOUT})
    localStorage.clear()
    history.push("/login")
    toast.success(`Logout realizado com sucesso. `)
  }
}

export {signIn, signOut}
