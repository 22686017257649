/*
  Sistema de roteamento com agrupamento de rotas

  As rotas são divididas, inicialmente, entre públicas e privadas.
  Estas podem ter outras subdivisões de acordo com cada contexto.
  Ex.: a rota /cadastro é privada e possui sub-rotas (/empresa e /escola).
  Ex.: a rota /login é pública e não possui sub-rotas.

  A ordem de listagem é importante. Não alterar, especialmente no componente que é exportado para o App
*/

import React from "react"
import {Redirect, Route, Switch} from "react-router-dom"

import DefaultRoute from "routes/defaultRoute"
import CadastroRoutesGroup from "routes/groups/cadastroRoutesGroup"
import EstagioRoutesGroup from "routes/groups/estagioRoutesGroup"

import Login from "pages/public/login"
import Dashboard from "pages/private/dashboard"

import generateRoutePath from "utils/generateRoutePath"
import { Sidebar } from "components/YeyComponents"
import Usuario from "pages/private/usuario"

const PrivateRoutesGroup = ({ match }) => {
  const PARENT_PATH = match.url || ""
  const routes = generateRoutePath(["dashboard", "usuario", "cadastro", "estagio"])
  return (
    <>
    <Sidebar />
    <Switch>
      <DefaultRoute exact path={PARENT_PATH + routes.dashboard} component={Dashboard} />
      <DefaultRoute exact path={PARENT_PATH + routes.usuario} component={Usuario} />
      <DefaultRoute path={PARENT_PATH + routes.cadastro} component={CadastroRoutesGroup} />
      <DefaultRoute path={PARENT_PATH + routes.estagio} component={EstagioRoutesGroup} />
      <DefaultRoute />
    </Switch>
    </>
  )
}

const PublicRoutesGroup = () => {
  const routes = generateRoutePath(["login"])
  return (
    <Switch>
      <Route exact path="/"><Redirect to="/login" /></Route>
      <DefaultRoute exact path={routes.login} component={Login} isLogin={true} />
      <DefaultRoute />
    </Switch>
  )
}

const Routes = () => (
  <Switch>
    <DefaultRoute path="/menu" component={PrivateRoutesGroup} isPrivate redirectURI="/login" />
    <DefaultRoute path="/" component={PublicRoutesGroup} />
    <DefaultRoute />
  </Switch>
)

export default Routes
