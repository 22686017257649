//import useState hook to create menu collapse state
import React, { useState } from "react"

//import react pro sidebar components
import {
    ProSidebar,
    Menu,
    MenuItem,
    SidebarHeader,
    SidebarFooter,
    SidebarContent,
    SubMenu,
} from "react-pro-sidebar"

//import icons from react icons
import { AiFillHome, AiFillFileText, AiOutlineMenu, AiOutlineClose, AiFillFolderAdd } from "react-icons/ai"
import { FaUserAlt } from "react-icons/fa"
import { FiLogOut } from "react-icons/fi"
import { MdDashboard } from "react-icons/md"
import { Link, useHistory } from 'react-router-dom'
import logoClient2 from 'assets/images/futuroAbsolutoBlack.png'
import logoYey from 'assets/images/logoYey.jpg'

//import sidebar css from react-pro-sidebar module and our custom css
import "react-pro-sidebar/dist/css/styles.css"
import "./Sidebar.css"
// import { Title } from './SidebarStyles'
import { useDispatch, useSelector } from "react-redux"
import { signOut } from "redux/actions/auth"

const Sidebar = () => {

    //create initial menuCollapse state using useState hook
    const [menuCollapse, setMenuCollapse] = useState(false)

    //create a custom function that will change menucollapse state from false to true and true to false
    // const menuIconClick = () => {
    //     //condition checking to change state from true to false and vice versa
    //     menuCollapse ? setMenuCollapse(false) : setMenuCollapse(true)
    // }

    const dispatch = useDispatch()
    const history = useHistory()
    const user = useSelector(state => state.auth.name || state.auth.username)
    const logout = () => dispatch(signOut(history))

    return (
        <>
            <div id="header">
                <ProSidebar collapsed={menuCollapse}>
                    <SidebarHeader>
                        {/* <Title>
                            YEY CONTRACTS Powered By Yey Company
                        </Title> */}
                    </SidebarHeader>
                    <SidebarHeader>
                        {/* <div className="logotext" >
                            <div className="closeicon">{menuCollapse ? "Logo" : "Logo"}</div>
                        </div> */}
                        {/* {<img src={logoYey} style={{ maxHeight: '100%', maxWidth: '100%', paddingBottom: '10px', paddingTop: '10px' }} alt='best logo in humankind' />} */}
                        <img src={logoClient2} style={{ maxHeight: '100%', maxWidth: '100%', paddingBottom: '10px', paddingTop: '10px' }} alt='Logotipo Futuro Absoluto' />
                    </SidebarHeader>
                    <SidebarContent>
                        <Menu iconShape="square">
                            <MenuItem icon={<MdDashboard size={15} />}>
                                Dashboard
                                <Link to="/menu/dashboard" />
                            </MenuItem>
                            <SubMenu title="Contratos" icon={<AiFillFileText size={15} />}>
                                <MenuItem>
                                    Novo
                                    <Link to="/menu/estagio/contratos/novo" />
                                </MenuItem>
                                <MenuItem>
                                    Listar
                                    <Link to="/menu/estagio/contratos" />
                                </MenuItem>
                            </SubMenu>
                            <SubMenu title="Cadastros" icon={<AiFillFolderAdd size={15} />}>
                                <MenuItem>
                                    Usuário
                                    <Link to="/menu/cadastro/usuario" />
                                </MenuItem>
                                <MenuItem>
                                    Empresa
                                    <Link to="/menu/cadastro/empresa" />
                                </MenuItem>
                                <MenuItem>
                                    Escola
                                    <Link to="/menu/cadastro/escola" />
                                </MenuItem>
                            </SubMenu>
                        </Menu>
                    </SidebarContent>
                    <SidebarFooter>
                        <Menu iconShape="square">
                            {/* <MenuItem
                                onClick={menuIconClick}
                                icon={
                                    menuCollapse
                                        ?
                                        <AiOutlineMenu size={15} />
                                        :
                                        <AiOutlineClose size={15} />
                                }
                            >
                                Minimizar Aba
                            </MenuItem> */}
                            <MenuItem icon={<FaUserAlt size={15} />}>
                                <span>{user}</span>
                                <Link to="/menu/usuario" />
                            </MenuItem>
                            <MenuItem icon={<FiLogOut size={15} />} onClick={logout}>
                                Sair
                            </MenuItem>
                        </Menu>
                    </SidebarFooter>
                </ProSidebar>

            </div>
        </>
    )
}

export default Sidebar
