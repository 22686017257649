import axios from "axios"
import { getToken } from './token'
import AUTH_TYPES from "redux/types/auth"
import errorHandler from "utils/errorHandler"
import { toast } from "react-hot-toast"

const URL = {
    dev: {
        localhost: `http://localhost:3001/v1`,
        digitalOcean: `http://159.203.191.251:3001/v1`,
        gitPod: `https://3001-tan-grouse-vz7xz3hm.ws-us16.gitpod.io/v1`,
        heroku: `https://futuro-absoluto-dev-api.herokuapp.com/v1`,
    },
    prod: {
        aws: `https://8m9wtdl7hj.execute-api.us-east-2.amazonaws.com/v1`,
        heroku: `https://futabs-api-83460023fc5d.herokuapp.com/v1`
    }
}

const api = axios.create({
    baseURL: URL.prod.heroku
})

export const setupInterceptors = (store, history) => {
    api.interceptors.request.use(config => {
        const token = getToken()
        if (token) config.headers.Authorization = `Bearer ${token}`
        config.timeout = 20000
        config.timeoutErrorMessage = `O servidor demorou muito para responder. Tente novamente ou contate o suporte.`
        return config
    }, error => Promise.reject(error))

    api.interceptors.response.use(response => {
        return response
    }, error => {
        if (error.response.status === 401) {
            if (window.location.pathname !== `/login`) {
                store.dispatch({ type: AUTH_TYPES.LOGOUT })
                localStorage.clear()
                toast.error(errorHandler(error, true))
                history.push("/login")
            }
        }
        return Promise.reject(error)
    })
}

export default api
