import styled from 'styled-components'
import themeColors from './themes/Light'
import getWindowDimensions from 'components/hooks/UseWindowDimensions'

const { screenHeight, screenWidth } = getWindowDimensions

export const MainView = styled.div`
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    ${'' /* overflow: 'auto'; */}
    font-family: 'Helvetica';
`

export const handleHeight = (value) => {

    switch (typeof value) {
        case 'number':
            if (value > 1) {
                return value
            }
            else {
                const newHeight = screenHeight * value
                return newHeight
            }

        case 'string':
            const stringValue = value.split('px')
            if (stringValue[1] === '') {
                return parseInt(stringValue[0])
            } else {
                return value
            }

        default:
            return 0
    }
}

export const handleWidth = (value) => {

    switch (typeof value) {
        case 'number':
            if (value > 1) {
                return value
            }
            else {
                const newWidth = screenWidth * value
                return newWidth
            }

        case 'string':
            const stringValue = value.split('px')
            if (stringValue[1] === '') {
                return parseInt(stringValue[0])
            } else {
                return value
            }

        default:
            return 0
    }
}

export const convertToNumber = (value) => {
    switch (typeof value) {
        case 'number':
            return value

        case 'string':
            const numberString = value.split('%')
            let number = value

            if (value.includes('%')) {
                number = numberString[0] / 100
            }

            return Number(number)

        default:
            return 0
    }
}

export const convertToPercentage = (value) => {
    switch (typeof value) {
        case 'number':
            return `${value * 100}%`

        case 'string':
            return value

        default:
            return '0%'
    }
}

// Utilizado para margin e padding
// value: 'margin' ou 'padding'
// side: 'top', 'bottom', 'left', 'right'
// Exemplo:
// marginTop: (typeof margin === 'number' || typeof margin === 'string')
// 	? `$height * ${convertToNumber(margin)}`
// 	: ((margin && margin.top) && `$height * ${convertToNumber(margin.top)}`),
export const setSpacing = (value, side, height = screenHeight, defaultValue = 0) => {
    //const { screenWidth } = getWindowDimensions

    switch (typeof value) {
        case 'number':
            return value

        case 'string':
            return value // height * convertToNumber(value)

        case 'object':
            let spacingValue = 0
            switch (side) {
                case 'top':
                    if (value && typeof value[side] === 'string') {
                        spacingValue = value[side]
                        // const stringVerticalValue = value[side].split('px')
                        // console.log('value[side]: ', value[side], stringVerticalValue)
                        // if (stringVerticalValue[1] === '' && !isNaN(stringVerticalValue[0])) {
                        // 	spacingValue = parseInt(stringVerticalValue[0])
                        // } else {
                        // 	spacingValue = height * convertToNumber(value[side])
                        // }
                    }
                    break
                case 'bottom':
                    if (value && typeof value[side] === 'string') {
                        spacingValue = value[side]
                    }
                    break

                case 'left':
                    if (typeof value[side] === 'string') {
                        spacingValue = value[side]
                    }
                    break
                case 'right':
                    if (typeof value[side] === 'string') {
                        spacingValue = value[side]
                    }
                    break

                default:
                    spacingValue = 0
                    break
            }
            return spacingValue

        default:
            return defaultValue
    }
}

// Utilizado para alinhar o componente em relação a View pai
// align: 'margin' ou 'padding'
// orientation: 'horizontal' ou 'vertical'
export const setAlignment = (align, orientation, defaultValue = 'flex-start') => {
    switch (typeof align) {
        case 'string':
            if (align === 'noAlign') {
                return undefined
            } else {
                return align
            }

        case 'object':
            return align[orientation]

        default:
            return defaultValue
    }
}

export const setBorder = (border, orientation, defaultValue = 0) => {
    switch (typeof border) {
        case 'string':
            const stringValue = border.split('px')
            if (stringValue[1] === '') {
                return parseInt(stringValue[0])
            } else {
                return border
            }

        case 'object':
            if (typeof border['width'] !== 'undefined') {
                return border['width']
            } else {
                return border[orientation]
            }


        default:
            return defaultValue
    }
}

export const setBorderRadius = (border, orientation, defaultValue = undefined) => {
    if (typeof border !== 'undefined') {
        switch (typeof border.radius) {
            case 'string':
            case 'number':
                return border.radius

            case 'object':
                return border.radius[orientation]

            default:
                return defaultValue
        }
    } else {
        return defaultValue
    }
}


export const colors = {
    ...themeColors,
}
