/** Texto default do sistema
 * @param {var} value - Valor do componente
 * @param {text} label - Texto mostrado ao lado do componente
 * @param {object} labelProps - Objeto de props passadas para a label. Props aceitas sao as mesmas que o componente Text
 *
 * Styles
 * @param {number, string} width - Largura do texto, valor em porcentagem. Ex: 0.7 ou '60%'.
 * @param {number, string} height - Altura do texto, valor em porcentagem. Ex: 0.2 ou '30%'.
 * @param {string, object} align - Alinhamento do texto, opções: 'flex-start', 'center', 'flex-end'. Se for uma string aplicará nos dois eixos. Se for um objeto pode receber {horizontal: <string>, vertical: <string>} para aplicar dois alinhamentos distintos (default: 'flex-start').
 * @param {string} backgroundColor ADICIONAR - Hex da cor de fundo do texto. Ex: '#FF0066'.
 * @param {object} border - Recebe as propriedades: {width: <number>, color: <string>}.
 * @param {string, number, object} margin - Espaçamento da view do texto, exemplos: '10%', 0.3. Se for uma string aplicará nos 4 cantos. Se for um objeto pode receber {top: <value>, bottom: <value>, left: <value>, right: <value>} para aplicar espaçamentos diferentes.
 * @param {string, number, object} padding - Espaçamento do texto até sua view, exemplos: '20%', 0.45. Se for uma string aplicará nos 4 cantos. Se for um objeto pode receber {top: <value>, bottom: <value>, left: <value>, right: <value>} para aplicar espaçamentos diferentes.
 * @param {number} size - Tamanho da fonte do texto, se não for passado nada, o padrão retornado será 14.
 * @param {string} fontWeight - Aplica o estilo na fonte.
 * @param {string} fontFamily ADICIONAR - Aplica uma nova fonte.
 * @param {string} color - Hex da cor do texto (default: '#000').
**/

import React, { useCallback, useState, memo } from 'react'
import { Row, Col, Text } from 'components/YeyComponents'
import InputMask from 'react-input-mask'
import { InputMaskStyle, InputStyle } from './InputStyle'
import MaterialInput from '@material-ui/core/Input'
import './InputCss.css'
import { InputAdornment, IconButton } from "@material-ui/core"
import Visibility from "@material-ui/icons/Visibility"
import VisibilityOff from "@material-ui/icons/VisibilityOff"

const Input = memo((props) => {
    const { label, labelProps, mask, maskPlaceholder, type, maskOptions, datePicker, placeholder, readOnlyHandler, alwaysShowMask, value, onChangeValue, beforeChange, ref, keyValue, disabled } = props

    let maskHandler = ''
    let maskPlaceholderHandler = ''
    const [isPasswordHidden, setIsPasswordHidden] = useState(true)

    //console.log('Inputkey', keyValue)

    if (mask && typeof mask === 'string') {
        switch (mask.toLowerCase()) {
            case 'cpf':
                maskHandler = '999.999.999-99'
                maskPlaceholderHandler = '000.000.000-00'
                break
            case 'rg':
                maskHandler = '99.999.99*-**'
                maskPlaceholderHandler = '00.000.000-00'
                break
            case 'cep':
                maskHandler = '99999-999'
                // maskPlaceholderHandler = '00000-000'
                break
            case 'cnpj':
                maskHandler = '99.999.999/9999-99'
                // maskPlaceholderHandler = '00.000.000/0000-00'
                break
            case 'ie': //Válida apenas para o estado de SP
                maskHandler = '999.999.999.999'
                maskPlaceholderHandler = '000.000.000.000'
                break
            case 'date':
                maskHandler = '99/99/9999'
                maskPlaceholderHandler = '00/00/0000'
                break
            case 'salary':
                // maskHandler = '000.000.000.000,00'
                // maskPlaceholderHandler = 'R$ 000.000.000.000,00'
                break
            default:
                maskHandler = ''
                maskPlaceholderHandler = ''
                break
        }
    }

    const changeValueHandler = (newValue) => {
        //console.log('changeValueHandler: ', newValue)
        // if(newValue && newValue.length > 5){
        //     return 'aaa'
        // }
        // onChangeValue('aaa')
        // if (mask && mask.toLowerCase() === 'salary' && newValue) {
        //     newValue = newValue.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' });
        // }
        onChangeValue && typeof onChangeValue === 'function' && onChangeValue(newValue)
    }

    const beforeChangeValueHandler = (newValue) => {
        //console.log('beforeChangeValueHandler')
        // if (mask && mask.toLowerCase() === 'salary' && newValue) {
        //     newValue = newValue.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' });
        // }
        // console.log('beforeChangeValueHandler2', newValue)
        // return { ...nextState, newValue }
        // newValue && beforeChange && typeof beforeChange === 'function' && newValue && beforeChange(newValue?.target?.value)

        if(mask && mask.toLowerCase() === 'rg') {
          if(newValue && typeof newValue === 'object') {
            let rgTrimmed = newValue?.value?.toUpperCase().replace('.', '').replace('-', '').replace(/[^\dX]/g,'')
            if(rgTrimmed.length === 8 || rgTrimmed.length === 9) {
              rgTrimmed = rgTrimmed.replace(/^(\d{1,2})(\d{3})(\d{3})([\dX])$/,'$1.$2.$3-$4')
              newValue.value = rgTrimmed
            }
          }
        }
        return newValue
    }

    const preventMinus = (e) => {
        if (e.code === 'Minus') {
            e.preventDefault()
        }
    }

    const handleClickShowPassword = () => setIsPasswordHidden(!isPasswordHidden)
    const handleMouseDownPassword = () => setIsPasswordHidden(!isPasswordHidden)

    return (
        <Row width='auto' {...props}>
            {typeof label == 'string' &&
                <Text
                    margin={{ right: '1em' }}
                    type='label'
                    {...labelProps}
                >
                    {label}
                </Text>
            }
            <InputMask
                // {...props}
                value={value}
                onChange={(newValue) => changeValueHandler(newValue?.target?.value)}
                // onChange={onChangeValue}
                beforeMaskedValueChange={beforeChangeValueHandler}
                maskChar=''
                mask={maskHandler}
                maskPlaceholder={maskPlaceholderHandler}
                alwaysShowMask={alwaysShowMask}
                readOnly={readOnlyHandler}
                placeholder={placeholder}
                style={{ ...InputMaskStyle }}
                ref={ref}
                disabled={disabled}
            >
                {/* {() => <InputStyle value={value} />} */}
                {(inputProps) => {
                    //console.log('inputProps', inputProps)
                    return <MaterialInput
                        {...inputProps}
                        style={{ width: '100%' }}
                        disabled={disabled}
                        key={keyValue}
                        placeholder={placeholder}
                        type={
                            type === 'password' ?
                                isPasswordHidden
                                    ?
                                    'password'
                                    :
                                    'text'
                                :
                                type
                        }
                        onKeyPress={preventMinus}
                        readOnly={readOnlyHandler}
                        value={value}
                        endAdornment={type === 'password' &&
                            <InputAdornment position="end" >
                                <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={handleClickShowPassword}
                                    onMouseDown={handleMouseDownPassword}
                                    tabIndex="-1"
                                >
                                    {isPasswordHidden ? <Visibility /> : <VisibilityOff />}
                                </IconButton>
                            </InputAdornment>
                        }
                    />
                }
                }
            </InputMask>
        </Row>
    )
})

export default Input
