import COMBO_TYPES from 'redux/types/combos'

const INITIAL_STATE = {
  companiesList: [],
  schoolsList: [],
  statesList: [],
  revocationReasonsList: [],
  citiesList: [],
  scholarshipStatus: [],
  workingSchedule: [],
  insuranceData: {
    holder: null,
    policyCode: null
  }
}
export default function comboReducer(state = INITIAL_STATE, action = {}) {
  switch (action.type) {
    case COMBO_TYPES.LOAD_ALL:
      return {...state, ...action.data}
    case COMBO_TYPES.LOAD_CITIES_FROM_STATE:
      return {...state, citiesList: action.citiesList}
    default:
      return state
  }
}
