import jwt_decoded from "jwt-decode"
const TOKEN_KEY = "@YEY_CONTRACTS_APP_KEY"

const isValidJWT = token => RegExp(/^([a-zA-Z0-9_=]+)\.([a-zA-Z0-9_=]+)\.([a-zA-Z0-9_\-+/=]*)/).test(token)

const extractDataFromJWTToken = token => {
  if(isValidJWT(token)) {
    return jwt_decoded(token)
  } else {
    return null
  }
}

const getToken = () => {
  const ls = localStorage.getItem(TOKEN_KEY)
  if(ls && ls.length > 0) {
    return JSON.parse(ls)
  } else {
    return null
  }
}

const setToken = token => localStorage.setItem(TOKEN_KEY, JSON.stringify(token))

export {getToken, setToken, isValidJWT, extractDataFromJWTToken}