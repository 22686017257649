import React from "react"
import {Redirect, Route} from "react-router-dom"

import NotFound from "pages/notFound"

import hasAuthenticated from "utils/hasAuthenticated"

const DefaultRoute = ({isPrivate = false, redirectURI = "/", isLogin = false, component: Component, ...rest}) => {
  if(!Component) return <NotFound />
  return (
    <Route {...rest} render = {props => {
      if(isPrivate === false && isLogin === true && hasAuthenticated() === true) {
        return <Redirect to="/menu/dashboard" />
      }
      if(isPrivate === false) {
        return <Component {...props} />
      }
      if(hasAuthenticated() === true) {
        return <Component {...props} />
      } else {
        return <Redirect to="/login" />
      }
    }} />
  )
}

export default DefaultRoute
